// src/pages/website/Data.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { fetchData } from '../../api';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';

// Импорт Chart.js и компонентов для графиков
import { Line, Pie, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

/** ====== Стили ====== */

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 10px;
`;

const AuthInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const AuthButton = styled.button`
  padding: 10px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

/** КОНСТАНТЫ ДЛЯ ГРАФИКОВ */
const CHART_COLORS = {
    primary: {
        main: 'rgb(75,192,192)',
        background: 'rgba(75,192,192,0.2)',
        border: 'rgba(75,192,192,1)'
    },
    secondary: {
        main: 'rgb(54,162,235)',
        background: 'rgba(54,162,235,0.2)',
        border: 'rgba(54,162,235,1)'
    },
    tertiary: {
        main: 'rgb(255,99,132)',
        background: 'rgba(255,99,132,0.2)',
        border: 'rgba(255,99,132,1)'
    },
    quaternary: {
        main: 'rgb(255,205,86)',
        background: 'rgba(255,205,86,0.2)',
        border: 'rgba(255,205,86,1)'
    },
    // Улучшенная палитра для пирога
    pieColors: [
        '#4BC0C0', // бирюзовый
        '#36A2EB', // синий
        '#FF6384', // розовый
        '#FFCD56', // желтый
        '#9966FF', // фиолетовый
        '#FF9F40', // оранжевый
        '#607D8B', // серо-синий
        '#4CAF50', // зеленый
        '#9C27B0', // пурпурный
        '#F44336', // красный
        '#3F51B5', // индиго
        '#8BC34A', // светло-зеленый
        '#009688', // темно-бирюзовый
        '#E91E63', // розово-красный
        '#795548'  // коричневый
    ]
};

const COMMON_CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
                padding: 15,
                font: {
                    size: 12,
                    family: 'Roboto, Arial, sans-serif'
                }
            }
        },
        tooltip: {
            backgroundColor: 'rgba(0,0,0,0.8)',
            titleFont: {
                size: 14,
                family: 'Roboto, Arial, sans-serif',
                weight: 'bold'
            },
            bodyFont: {
                size: 13,
                family: 'Roboto, Arial, sans-serif'
            },
            padding: 10,
            cornerRadius: 4,
            displayColors: true
        }
    }
};

/** Дополнительные стили (фильтры, таблицы, кнопки, модалки и т.п.) */
const FilterForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const FormInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 10px;
`;

const FilterButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
`;

const DownloadButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
`;

const ResetButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
`;

const ToggleAnalyticsButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #17a2b8;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
`;

const SuccessMessage = styled.div`
  color: green;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

const Loading = styled.div`
  font-size: 18px;
  color: #007bff;
  text-align: center;
  margin-top: 20px;
`;

const TableResponsive = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
`;

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
  @media (max-width: 768px) {
    min-width: 600px;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  background-color: #f2f2f2;
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
`;

const PageButton = styled.button`
  padding: 8px 12px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &.active,
  &:hover {
    background-color: #007bff;
    color: #fff;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const NoData = styled.p`
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-top: 20px;
`;

/** Модальное окно */
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 80%;
  max-height: 90%;
  overflow: auto;
  padding: 20px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ccc;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background: #aaa;
  }
`;

const ChartsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  margin-top: 20px;
`;

const ChartSection = styled.div`
  flex: 1;
  min-width: 400px;
  text-align: center;
  margin-top: 30px;
`;

const WideChartWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  height: 500px;
`;

const LegendWrapper = styled.div`
  margin-top: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
`;

/** ========== ОСНОВНОЙ КОМПОНЕНТ ========== */

const Data = () => {
    const location = useLocation();
    const history = useHistory();
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Фильтры
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [pid, setPid] = useState('');
    const [rid, setRid] = useState('');

    // Пагинация
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;

    // Сообщение об успешной фильтрации
    const [successMessage, setSuccessMessage] = useState('');

    // Аутентификация
    const [authenticated, setAuthenticated] = useState(false);
    const [inputPassword, setInputPassword] = useState('');
    const HARDCODED_PASSWORD = 'Bcf2023';

    // Модальное окно аналитики
    const [showAnalytics, setShowAnalytics] = useState(false);

    /** Инициализация фильтров из URL */
    const initializeFiltersFromURL = useCallback(() => {
        const params = new URLSearchParams(location.search);
        const date = params.get('startDate');
        const pidParam = params.get('pid');
        const ridParam = params.get('rid');

        if (date) {
            setStartDate(date);
            setEndDate(date);
        }
        if (pidParam) {
            setPid(pidParam);
        }
        if (ridParam) {
            setRid(ridParam);
        }
    }, [location.search]);

    useEffect(() => {
        initializeFiltersFromURL();
    }, [initializeFiltersFromURL]);

    useEffect(() => {
        if (!authenticated) return;
        fetchData()
            .then((data) => {
                setAllData(data);
                setFilteredData(data);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error fetching data:', err);
                setError(err.message);
                setLoading(false);
            });
    }, [authenticated]);

    useEffect(() => {
        if (!authenticated) return;
        initializeFiltersFromURL();
    }, [initializeFiltersFromURL, location.search, allData, authenticated]);

    useEffect(() => {
        if (!authenticated) return;
        applyFilters();
    }, [startDate, endDate, pid, rid, allData, authenticated]);

    /** Применение фильтров */
    const applyFilters = useCallback(() => {
        let filtered = allData;

        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            if (start > end) {
                setError('Начальная дата не может быть позже конечной даты.');
                setFilteredData([]);
                setSuccessMessage('');
                return;
            }
        }
        setError(null);

        if (startDate) {
            const start = new Date(startDate);
            filtered = filtered.filter(item => new Date(item['Date']) >= start);
        }
        if (endDate) {
            const end = new Date(endDate);
            end.setHours(23, 59, 59, 999);
            filtered = filtered.filter(item => new Date(item['Date']) <= end);
        }
        if (pid.trim() !== '') {
            filtered = filtered.filter(item => String(item['PID']).trim() === pid.trim());
        }
        if (rid.trim() !== '') {
            filtered = filtered.filter(item => String(item['RID']).trim() === rid.trim());
        }
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [allData, startDate, endDate, pid, rid]);

    /** Обработка формы фильтров */
    const handleFilterSubmit = (e) => {
        e.preventDefault();
        applyFilters();
        const params = new URLSearchParams();
        if (startDate) params.append('startDate', startDate);
        if (pid) params.append('pid', pid);
        if (rid) params.append('rid', rid);
        history.push({ search: params.toString() });
    };

    /** Сброс фильтров */
    const handleResetFilters = () => {
        setStartDate('');
        setEndDate('');
        setPid('');
        setRid('');
        setFilteredData(allData);
        setCurrentPage(1);
        setError(null);
        setSuccessMessage('Фильтры сброшены.');
        history.push({ search: '' });
    };

    // Пагинация
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(filteredData.length / recordsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    /** Скачать JSON */
    const downloadJSON = () => {
        const dataStr = JSON.stringify(filteredData, null, 2);
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        let fileName = 'securities';
        const params = [];
        if (startDate) params.push(`date=${startDate}`);
        if (pid) params.push(`pid=${pid}`);
        if (rid) params.push(`rid=${rid}`);
        if (params.length > 0) {
            fileName += `_${params.join('_')}`;
        }
        fileName += `.json`;

        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /** Скачать CSV */
    const downloadCSV = () => {
        if (filteredData.length === 0) {
            setError('Нет данных для скачивания.');
            return;
        }

        const headers = Object.keys(filteredData[0]);
        const csvRows = [];
        csvRows.push(headers.join(','));

        filteredData.forEach(row => {
            const values = headers.map(header => {
                let escaped = String(row[header]).replace(/"/g, '""');
                if (escaped.search(/("|,|\n)/g) >= 0) {
                    escaped = `"${escaped}"`;
                }
                return escaped;
            });
            csvRows.push(values.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        let fileName = 'securities';
        const params = [];
        if (startDate) params.push(`date=${startDate}`);
        if (pid) params.push(`pid=${pid}`);
        if (rid) params.push(`rid=${rid}`);
        if (params.length > 0) {
            fileName += `_${params.join('_')}`;
        }
        fileName += `.csv`;

        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /** Аутентификация */
    const handleAuthSubmit = (e) => {
        e.preventDefault();
        if (inputPassword === HARDCODED_PASSWORD) {
            setAuthenticated(true);
            setError(null);
        } else {
            setError('Неверный пароль.');
        }
    };

    /** ========= ФУНКЦИИ ПОДГОТОВКИ ДАННЫХ ДЛЯ ГРАФИКОВ ========= */

    /** 1) Улучшенный Линейный график (по неделям) */
    const getLineChartData = () => {
        const getWeekStart = (date) => {
            const d = new Date(date);
            const day = d.getDay(); // 0=вс,1=пн,...6=сб
            const diff = (day + 6) % 7;
            d.setDate(d.getDate() - diff);
            d.setHours(0, 0, 0, 0);
            return d;
        };

        const weeklyCounts = {};
        filteredData.forEach(item => {
            const dt = new Date(item['Date']);
            const monday = getWeekStart(dt);
            const y = monday.getFullYear();
            const m = String(monday.getMonth() + 1).padStart(2, '0');
            const d = String(monday.getDate()).padStart(2, '0');
            const label = `${d}.${m}.${y}`;
            weeklyCounts[label] = (weeklyCounts[label] || 0) + 1;
        });

        const sortedLabels = Object.keys(weeklyCounts).sort((a, b) => {
            const [dayA, monthA, yearA] = a.split('.').map(Number);
            const [dayB, monthB, yearB] = b.split('.').map(Number);
            return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
        });
        const dataVals = sortedLabels.map(l => weeklyCounts[l]);

        return {
            labels: sortedLabels,
            datasets: [
                {
                    label: 'Количество записей (по неделям)',
                    data: dataVals,
                    fill: true,
                    backgroundColor: CHART_COLORS.primary.background,
                    borderColor: CHART_COLORS.primary.border,
                    pointBackgroundColor: CHART_COLORS.primary.border,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: CHART_COLORS.primary.border,
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    borderWidth: 2,
                    tension: 0.3
                }
            ]
        };
    };

    const lineChartOptions = {
        ...COMMON_CHART_OPTIONS,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 11
                    },
                    maxRotation: 45,
                    minRotation: 45
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    precision: 0
                }
            }
        },
        plugins: {
            ...COMMON_CHART_OPTIONS.plugins,
            title: {
                display: true,
                text: 'Динамика заявок по неделям',
                font: {
                    size: 18,
                    family: 'Roboto, Arial, sans-serif',
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 20
                }
            }
        }
    };

    /** 2) Улучшенная диаграмма "Как узнали о нас?" (Pie) */
    const getPieChartData = () => {
        const sourceCounts = {};
        filteredData.forEach(item => {
            const source = item['How did you hear about us?'] || 'Неизвестно';
            sourceCounts[source] = (sourceCounts[source] || 0) + 1;
        });

        // Сортировка данных по убыванию
        const sortedEntries = Object.entries(sourceCounts).sort((a, b) => b[1] - a[1]);
        const labels = sortedEntries.map(entry => entry[0]);
        const data = sortedEntries.map(entry => entry[1]);

        return {
            labels,
            datasets: [
                {
                    label: 'Распределение источников',
                    data,
                    backgroundColor: CHART_COLORS.pieColors.slice(0, labels.length),
                    borderColor: 'white',
                    borderWidth: 2,
                    hoverOffset: 10,
                    hoverBorderWidth: 3
                }
            ]
        };
    };

    const pieChartOptions = {
        ...COMMON_CHART_OPTIONS,
        plugins: {
            ...COMMON_CHART_OPTIONS.plugins,
            title: {
                display: true,
                text: 'Как узнали о нас?',
                font: {
                    size: 18,
                    family: 'Roboto, Arial, sans-serif',
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 20
                }
            },
            tooltip: {
                ...COMMON_CHART_OPTIONS.plugins.tooltip,
                callbacks: {
                    label: function(context) {
                        const label = context.label || '';
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                        const percentage = Math.round((context.raw / total) * 100);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            }
        }
    };

    /** 3) Улучшенный Топ-10 конференций (Pie) */
    const getTop10ConferencePieData = useCallback(() => {
        const confCounts = {};
        filteredData.forEach(item => {
            const conf = item['Conference'] || 'No Conference';
            confCounts[conf] = (confCounts[conf] || 0) + 1;
        });

        let arr = Object.entries(confCounts);
        arr.sort((a, b) => b[1] - a[1]);

        if (arr.length > 10) {
            const top10 = arr.slice(0, 10);
            const others = arr.slice(10);
            const othersSum = others.reduce((sum, [_, cnt]) => sum + cnt, 0);
            top10.push(['Другие конференции', othersSum]);
            arr = top10;
        }

        const labels = arr.map(x => x[0]);
        const data = arr.map(x => x[1]);

        return {
            labels,
            datasets: [
                {
                    label: 'Топ-10 конференций',
                    data,
                    backgroundColor: CHART_COLORS.pieColors.slice(0, labels.length),
                    borderColor: 'white',
                    borderWidth: 2,
                    hoverOffset: 10,
                    hoverBorderWidth: 3
                }
            ]
        };
    }, [filteredData]);

    const conferencePieOptions = {
        ...COMMON_CHART_OPTIONS,
        plugins: {
            ...COMMON_CHART_OPTIONS.plugins,
            title: {
                display: true,
                text: 'Топ-10 конференций',
                font: {
                    size: 18,
                    family: 'Roboto, Arial, sans-serif',
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 20
                }
            },
            tooltip: {
                ...COMMON_CHART_OPTIONS.plugins.tooltip,
                callbacks: {
                    label: function(context) {
                        const label = context.label || '';
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                        const percentage = Math.round((context.raw / total) * 100);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            }
        }
    };

    /** 4) Улучшенная Аналитика по дням недели (Bar) */
    const getDayOfWeekData = () => {
        const dayCounts = [0, 0, 0, 0, 0, 0, 0];
        filteredData.forEach(item => {
            const d = new Date(item['Date']);
            const day = d.getDay();
            dayCounts[day]++;
        });

        const labels = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

        return {
            labels,
            datasets: [
                {
                    label: 'Заявки по дням недели',
                    data: dayCounts,
                    backgroundColor: CHART_COLORS.secondary.background,
                    borderColor: CHART_COLORS.secondary.border,
                    borderWidth: 2,
                    borderRadius: 6,
                    hoverBackgroundColor: CHART_COLORS.secondary.border,
                    hoverBorderColor: 'white'
                }
            ]
        };
    };

    const weekdayBarOptions = {
        ...COMMON_CHART_OPTIONS,
        indexAxis: 'y',  // Горизонтальный вариант
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    precision: 0
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            ...COMMON_CHART_OPTIONS.plugins,
            title: {
                display: true,
                text: 'Распределение заявок по дням недели',
                font: {
                    size: 18,
                    family: 'Roboto, Arial, sans-serif',
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 20
                }
            }
        }
    };

    /** 5) Улучшенная Аналитика по времени суток (Bar) */
    const getTimeOfDayData = () => {
        const counts = { night: 0, morning: 0, day1: 0, day2: 0, evening: 0 };

        filteredData.forEach(item => {
            const h = new Date(item['Date']).getHours();
            if (h >= 0 && h < 6) {
                counts.night++;
            } else if (h >= 6 && h < 12) {
                counts.morning++;
            } else if (h >= 12 && h < 15) {
                counts.day1++;
            } else if (h >= 15 && h < 17) {
                counts.day2++;
            } else if (h >= 17 && h < 24) {
                counts.evening++;
            }
        });

        const labels = [
            'Ночь (0-6)',
            'Утро (6-12)',
            'День (12-15)',
            'День (15-17)',
            'Вечер (17-24)'
        ];

        const data = [
            counts.night,
            counts.morning,
            counts.day1,
            counts.day2,
            counts.evening
        ];

        return {
            labels,
            datasets: [
                {
                    label: 'Заявки по времени суток',
                    data,
                    backgroundColor: [
                        'rgba(73, 89, 151, 0.7)',   // темно-синий для ночи
                        'rgba(255, 193, 7, 0.7)',  // янтарный для утра
                        'rgba(255, 87, 34, 0.7)',  // оранжевый для дня1
                        'rgba(233, 30, 99, 0.7)',  // розовый для дня2
                        'rgba(96, 125, 139, 0.7)'  // серо-синий для вечера
                    ],
                    borderColor: [
                        'rgba(73, 89, 151, 1)',
                        'rgba(255, 193, 7, 1)',
                        'rgba(255, 87, 34, 1)',
                        'rgba(233, 30, 99, 1)',
                        'rgba(96, 125, 139, 1)'
                    ],
                    borderWidth: 2,
                    borderRadius: 6,
                    hoverOffset: 10
                }
            ]
        };
    };

    const timeOfDayBarOptions = {
        ...COMMON_CHART_OPTIONS,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    precision: 0
                }
            }
        },
        plugins: {
            ...COMMON_CHART_OPTIONS.plugins,
            title: {
                display: true,
                text: 'Распределение заявок по времени суток',
                font: {
                    size: 18,
                    family: 'Roboto, Arial, sans-serif',
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 20
                }
            }
        }
    };

    if (!authenticated) {
        return (
            <AuthContainer>
                <h2>Введите пароль для доступа к данным</h2>
                <AuthForm onSubmit={handleAuthSubmit}>
                    <AuthInput
                        type="password"
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                        placeholder="Пароль"
                        required
                    />
                    <AuthButton type="submit">Войти</AuthButton>
                </AuthForm>
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </AuthContainer>
        );
    }

    return (
        <Container>
            <h1>Agenda Data</h1>

            {/* Форма фильтрации */}
            <FilterForm onSubmit={handleFilterSubmit}>
                <FormGroup>
                    <FormLabel htmlFor="startDate">От:</FormLabel>
                    <FormInput
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="endDate">До:</FormLabel>
                    <FormInput
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="pid">PID:</FormLabel>
                    <FormInput
                        type="text"
                        id="pid"
                        value={pid}
                        onChange={(e) => setPid(e.target.value)}
                        placeholder="Введите PID"
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="rid">RID:</FormLabel>
                    <FormInput
                        type="text"
                        id="rid"
                        value={rid}
                        onChange={(e) => setRid(e.target.value)}
                        placeholder="Введите RID"
                    />
                </FormGroup>
                <FormButtons>
                    <FilterButton type="button" onClick={downloadJSON}>
                        Скачать JSON
                    </FilterButton>
                    <DownloadButton type="button" onClick={downloadCSV}>
                        Скачать CSV
                    </DownloadButton>
                    <ResetButton type="button" onClick={handleResetFilters}>
                        Сбросить
                    </ResetButton>
                    <ToggleAnalyticsButton type="button" onClick={() => setShowAnalytics(true)}>
                        Показать аналитику
                    </ToggleAnalyticsButton>
                </FormButtons>
            </FilterForm>

            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            {error && <ErrorMessage>Ошибка: {error}</ErrorMessage>}
            {loading && <Loading>Загрузка...</Loading>}

            {!loading && !error && (
                filteredData.length > 0 ? (
                    <>
                        <TableResponsive>
                            <DataTable>
                                <thead>
                                <tr>
                                    <TableHeader>Date</TableHeader>
                                    <TableHeader>PID</TableHeader>
                                    <TableHeader>RID</TableHeader>
                                    <TableHeader>Conference</TableHeader>
                                    <TableHeader>Full Name</TableHeader>
                                    <TableHeader>Email</TableHeader>
                                    <TableHeader>Phone</TableHeader>
                                    <TableHeader>Job Title</TableHeader>
                                    <TableHeader>Company</TableHeader>
                                    <TableHeader>How did you hear about us?</TableHeader>
                                </tr>
                                </thead>
                                <tbody>
                                {currentRecords.map((row, idx) => (
                                    <TableRow key={idx}>
                                        {/* Дата + время в европейском формате */}
                                        <TableData>
                                            {new Date(row['Date']).toLocaleDateString('ru-RU')} {' '}
                                            {new Date(row['Date']).toLocaleTimeString('ru-RU', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </TableData>
                                        <TableData>{row['PID']}</TableData>
                                        <TableData>{row['RID']}</TableData>
                                        <TableData title={row['Conference']}>
                                            {row['Conference'].length > 30
                                                ? row['Conference'].substring(0, 30) + '...'
                                                : row['Conference']
                                            }
                                        </TableData>
                                        <TableData>{row['Full Name']}</TableData>
                                        <TableData>{row['Email']}</TableData>
                                        <TableData>{row['Phone']}</TableData>
                                        <TableData>{row['Job Title']}</TableData>
                                        <TableData>{row['Company']}</TableData>
                                        <TableData title={row['How did you hear about us?']}>
                                            {row['How did you hear about us?'].length > 30
                                                ? row['How did you hear about us?'].substring(0, 30) + '...'
                                                : row['How did you hear about us?']
                                            }
                                        </TableData>
                                    </TableRow>
                                ))}
                                </tbody>
                            </DataTable>
                        </TableResponsive>

                        <Pagination>
                            <PageButton
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Предыдущая
                            </PageButton>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(num => (
                                <PageButton
                                    key={num}
                                    onClick={() => paginate(num)}
                                    className={num === currentPage ? 'active' : ''}
                                >
                                    {num}
                                </PageButton>
                            ))}
                            <PageButton
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Следующая
                            </PageButton>
                        </Pagination>

                        {/* Модальное окно с аналитикой */}
                        {showAnalytics && (
                            <ModalOverlay>
                                <ModalContent>
                                    <CloseButton onClick={() => setShowAnalytics(false)}>X</CloseButton>
                                    <h2>Аналитика</h2>

                                    {/* Первая строка: Линейный график и "Как узнали о нас?" */}
                                    <ChartsRow>
                                        <ChartSection>
                                            <h3>График заявок по неделям</h3>
                                            <WideChartWrapper>
                                                <Line
                                                    data={getLineChartData()}
                                                    options={lineChartOptions}
                                                />
                                            </WideChartWrapper>
                                        </ChartSection>

                                        <ChartSection>
                                            <h3>Как узнали о нас?</h3>
                                            <WideChartWrapper>
                                                <Pie
                                                    data={getPieChartData()}
                                                    options={pieChartOptions}
                                                />
                                            </WideChartWrapper>
                                        </ChartSection>
                                    </ChartsRow>

                                    {/* Вторая строка: Топ-10 конференций (Pie) + легенда */}
                                    <ChartSection>
                                        <h3>Топ-10 конференций</h3>
                                        <WideChartWrapper>
                                            <Pie
                                                data={getTop10ConferencePieData()}
                                                options={conferencePieOptions}
                                            />
                                        </WideChartWrapper>
                                        <LegendWrapper>
                                            <h4>Детальная информация о конференциях</h4>
                                            <ul>
                                                {getTop10ConferencePieData().labels.map((label, i) => {
                                                    const color = getTop10ConferencePieData().datasets[0].backgroundColor[i];
                                                    const value = getTop10ConferencePieData().datasets[0].data[i];
                                                    const total = getTop10ConferencePieData().datasets[0].data.reduce((acc, val) => acc + val, 0);
                                                    const percentage = Math.round((value / total) * 100);
                                                    return (
                                                        <li
                                                            key={i}
                                                            style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
                                                        >
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '14px',
                                                        height: '14px',
                                                        backgroundColor: color,
                                                        marginRight: '10px',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                                            <span style={{ fontWeight: 'bold' }}>{i + 1}.</span>&nbsp;
                                                            <span style={{ maxWidth: '70%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</span>
                                                            &nbsp;—&nbsp;<b>{value}</b> ({percentage}%)
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </LegendWrapper>
                                    </ChartSection>

                                    {/* Третья строка: По дням недели и по времени суток */}
                                    <ChartsRow>
                                        <ChartSection>
                                            <h3>По дням недели</h3>
                                            <WideChartWrapper>
                                                <Bar
                                                    data={getDayOfWeekData()}
                                                    options={weekdayBarOptions}
                                                />
                                            </WideChartWrapper>
                                        </ChartSection>

                                        <ChartSection>
                                            <h3>По времени суток</h3>
                                            <WideChartWrapper>
                                                <Bar
                                                    data={getTimeOfDayData()}
                                                    options={timeOfDayBarOptions}
                                                />
                                            </WideChartWrapper>
                                        </ChartSection>
                                    </ChartsRow>

                                </ModalContent>
                            </ModalOverlay>
                        )}
                    </>
                ) : (
                    <NoData>Данные не найдены.</NoData>
                )
            )}
        </Container>
    );
};

export default Data;
