import React from 'react';

function Footer() {
  return (
    <footer>
        <div className="footer-wrapper">
            <ul className="social-container">
                <li>
                    <img src={require('../../assets/images/logo.png')} alt='' />
                </li>
                <li className="social">
                    <a target="_blank" rel="noreferrer" href='https://www.instagram.com/bcf.event/'>
                        <img src={require('../../assets/images/9.png')} alt='' />
                    </a>
                    <a target="_blank" rel="noreferrer" href='https://www.facebook.com/bcfgroup.eu'>
                        <img src={require('../../assets/images/7.png')} alt='' />
                    </a>
                    <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/business-conference-facilitation/'>
                        <img src={require('../../assets/images/8.png')} alt='' />
                    </a>
                </li>

            </ul>
            <ul className="departaments">
                <ul>
                    <li>Marketing Department<span> +420 228 227 162</span></li>
                    <li>Production Department<span> +420 228 227 163</span></li>
                </ul>
                <ul>
                    <li>Operations Department<span> +420 228 227 164</span></li>
                    <li><span>general@bcf-events.com</span></li>
                </ul>
            </ul>
            <ul className="links">
                <a href="/userfulllink">Useful Links</a>
                <a href="/privacy">Data policy</a>
                <a href="/terms">Terms & Conditions</a>
                <a href="/cancelation">Cancelation policy</a>
            </ul>
        </div>
        <div className="laptop-footer">
            <div className="flex-laptop">
                <div className="laptop-logo">
                    <img src={require('../../assets/images/logo.png')} alt='' className="laptop-logo-img"/>
                </div>
                <li className="social">
                    <a target="_blank" rel="noreferrer" href='https://www.instagram.com/bcf.event/'>
                        <img src={require('../../assets/images/instagram-mobile.png')} alt='' />
                    </a>
                    <a target="_blank" rel="noreferrer" href='https://www.facebook.com/bcfgroup.eu'>
                        <img src={require('../../assets/images/7.png')} alt='' />
                    </a>
                    <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/business-conference-facilitation/'>
                        <img src={require('../../assets/images/linkedin-mobile.png')} alt='' />
                    </a>
                </li>
            </div>
            <span className="line-footer-laptop"></span>
            <div>
                <ul className="laptop-flex">
                    <li>
                        <p className="title-laptop">Marketing Department</p>
                        <p className="contact-laptop">+420 228 227 162</p>
                        <p className="title-laptop">Production Department</p>
                        <p className="contact-laptop">+420 228 227 163</p>
                    </li>
                    <li>
                        <p className="title-laptop">Operations Department</p>
                        <p className="contact-laptop">+420 228 227 164</p>
                        <p className="title-laptop">E-mail</p>
                        <p className="contact-laptop">general@bcf-events.com</p>
                    </li>
                    <li>
                        <a href="/userfulllink">
                            <p className="title-laptop">Useful Links</p>
                        </a>
                        <a href='/terms'><p className="title-laptop">Terms & Conditions</p></a>
                        <a href='/privacy'><p className="title-laptop">Data policy</p></a>
                        <a href="/cancelation"><p className="title-laptop">Cancelation policy</p></a>
                    </li>
                </ul>
                <p className="copyright-laptop">Copyright 2023 © All right reserved</p>
            </div>
        </div>
        <div className="footer-mobile">
            <div className="footer-logo">
                <img src={require('../../assets/images/logo.png')} alt='' />
            </div>
            <span className="line-footer"></span>
            <div>
                <ul className="footer-list">
                    <li>Marketing Department<span> +420 228 227 162</span></li>
                    <li>Production Department<span> +420 228 227 163</span></li>
                    <li>Operations Department<span> +420 228 227 164</span></li>
                    <li>E-mail<span>general@bcf-events.com</span></li>
                </ul>
            </div>
            <ul className="links-mobile">
                <a href="/policy">Data policy</a>
                <a href="/terms">Terms & Conditions</a>
                <a href="/cancelation">Cancelation policy</a>
            </ul>
            <span className="line-footer"></span>
            <ul className="social-mobile-footer">
                <li className="social">
                    <a target="_blank" rel="noreferrer noopener" href='https://www.instagram.com/bcf.event/'>
                        <img src={require('../../assets/images/instagram-mobile.png')} alt='' />
                    </a>
                    <a target="_blank" rel="noreferrer noopener" href='https://www.facebook.com/bcfgroup.eu'>
                        <img src={require('../../assets/images/7.png')} alt='' />
                    </a>
                    <a target="_blank" rel="noreferrer noopener" href='https://www.linkedin.com/company/business-conference-facilitation/'>
                        <img src={require('../../assets/images/linkedin-mobile.png')} alt='' />
                    </a>
                </li>

            </ul>
            <p className="copyright-mobile">Copyright 2023 © All right reserved</p>
        </div>
    </footer>
  );
}

export default Footer;