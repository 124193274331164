import React, { useState, useEffect } from 'react';
import { X, Mail, Zap, ArrowRight } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const DiscountBanner = ({ handleOpenModal }) => {
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // Show banner sooner (1 second instead of 2)
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleOpenSpecialModal = () => {
        handleOpenModal("agenda", "discount_banner");
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-bg-orange-600 tw-text-white tw-py-3 sm:tw-py-4 tw-z-50 tw-shadow-lg">
            <div className="tw-container tw-mx-auto tw-px-2 sm:tw-px-4">
                {/* Desktop version (hidden on small screens) */}
                <div className="tw-hidden sm:tw-flex tw-items-center tw-justify-center tw-gap-3">
                    {/* Discount badge with icon */}
                    <div className="tw-flex tw-items-center tw-gap-2">
                        <Zap className="tw-w-5 tw-h-5" />
                        <span className="tw-bg-white tw-text-orange-600 tw-px-3 tw-py-1 tw-rounded-md tw-font-bold tw-whitespace-nowrap tw-text-lg">
                            EXCLUSIVE 25% DISCOUNT!
                        </span>
                    </div>

                    {/* Description text */}
                    <p className="tw-text-lg tw-max-w-2xl tw-font-medium">
                        Leave your contact details now, and our manager will reach out with a special personalized offer! Limited time only!
                    </p>

                    {/* Buttons section */}
                    <div className="tw-flex tw-items-center tw-gap-3 tw-shrink-0">
                        <button
                            onClick={handleOpenSpecialModal}
                            className="tw-bg-white tw-text-orange-600 hover:tw-bg-orange-50 tw-px-4 tw-py-2 tw-rounded-md tw-transition-colors tw-font-bold tw-whitespace-nowrap tw-text-lg tw-flex tw-items-center"
                        >
                            Get Your Offer <ArrowRight className="tw-w-4 tw-h-4 tw-ml-1" />
                        </button>
                        <button
                            onClick={() => setIsVisible(false)}
                            className="tw-p-1 hover:tw-bg-orange-500 tw-rounded-full tw-transition-colors"
                        >
                            <X className="tw-w-6 tw-h-6" />
                        </button>
                    </div>
                </div>

                {/* Improved mobile version */}
                <div className="sm:tw-hidden tw-flex tw-flex-col tw-items-center tw-gap-2">
                    <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                        <div className="tw-flex tw-items-center">
                            <Zap className="tw-w-5 tw-h-5 tw-mr-1" />
                            <span className="tw-text-white tw-font-bold tw-text-base">
                                25% DISCOUNT!
                            </span>
                        </div>
                        <button
                            onClick={() => setIsVisible(false)}
                            className="tw-flex tw-items-center tw-justify-center"
                        >
                            <X className="tw-w-4 tw-h-4" />
                        </button>
                    </div>

                    <button
                        onClick={handleOpenSpecialModal}
                        className="tw-bg-white tw-text-orange-600 tw-font-bold tw-text-sm tw-rounded-md tw-py-1.5 tw-px-4 tw-w-full tw-flex tw-items-center tw-justify-center"
                    >
                        Get Your Special Offer <ArrowRight className="tw-w-3 tw-h-3 tw-ml-1" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DiscountBanner;