const allowedEmails = [
    //
    // 5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE
    //
    { email: "benjamin.schaeffner@ensingerplastics.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"andrew.billings@pepsico.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"l.pirondini@gruppofabbri.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"rob.rutjes@lyondellbasell.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"mark.redway@everoenergy.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"lmercante@it.lati.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"dlotti@it.lati.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"lposca@it.lati.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"johank@max-ai.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"olga.kibke@renolit.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"emilie.vedelblankschon@altana.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Robert.Hick@agc.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"AndreaGhu@poplast.it", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"i.chontzoglou@theoceancleanup.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"qing.cui@basf.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"marcel.scholten@norit.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"tobias.winkler@tesa.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"m.bascik@comfort.pl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"g.popowicz@comfort.pl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Alice.Leppin@tesa.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Danny.Stange@tesa.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Nancy.Laeveren@kaneka.be", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"jarmila.sykorova@yanfeng.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"t.kroner@mkt-gmbh.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"s.kirchner@mkt-gmbh.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"christian.krueger@basf.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"giuditta.vannucci@versalis.eni.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"anna.lisiak@stenarecycling.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"tomasz.czarny@stenarecycling.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Theresa.Emt@georgfischer.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"john.lenar@yparex.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"kristelmae.castillo@aliaxis.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"mbintner@penncolor.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"m.kuso@gabriel-chemie.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"patrick.lindweiler@steinert.de", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"thorsten.ehnert@mosca.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Marta.Sans@Milliken.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"elmar.schroeter@meraxis-group.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"krzysztof.gawlowski2@orlen.pl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"stauber-fedier.sara@ch.sika.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"merel.nooijens@tno.nl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"louda.grigoriadi@tno.nl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"andrea.hensen@daftrucks.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"daniel.malczewski@slovnaft.pl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"amelia.willis@renolit.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"lamatz@arsol-aromatics.de", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"jerome.gocyk@versalis.eni.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"W.Smits@quiks.nl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"anna.Skanse@sysav.se", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Vincent.Wilson@motherson.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"thomas.riethmann@gmx.de", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Buechner@arsol-aromatics.de", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"m.quibus@airtech.lu", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"lilian.steiner@sks-laupen.ch", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"marc-antoine.belthe@plasticenergy.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"josephine.munsch@elkem.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"stephan.weidenfeller@se.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"alexander.koechl@teijinaramid.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"SKORANIT@bazan.co.il", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"sumant@cpfl.co.in", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"josecarlos.catala@ivecogroup.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"b.lewis@lithiontechnologies.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"aida.arsenyan@forvia.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"Laura.Jonckheere@carpenter.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"gurunath.pv@eplglobal.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"melanie.graefen@vibracoustic.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"marcelo.faral@braskem.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"mamafe@aimplas.es", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"mleborgne@novacel-solutions.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"m.kubicka@elektrorecykling-polska.pl", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"kangc25@sk.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"rebecca.jung@leifheit.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"SMATIKOVA@novacel-solutions.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"csanchez@nexeoplastics.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"patricia_popescu@natureworksllc.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"robin.ammenhaeuser@currenta.biz", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"lauren.eberle@dyntool.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"rdharmapall@germanchamber.co.za", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"ville.nurminen@bewi.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"kristina.schlich@lyondellbasell.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"mmarlier@plarebel.be", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    { email:"gerard.kwakkenbos@sabic.com", eventName: "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE" },
    //
    // 4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE

    { email:"alexia@envoker.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"cindy.falk@jci.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"silvia.font@almirall.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"olga.soler@almirall.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"silvana.delaplazagargoles@bnpparibas.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"raquel.garciaossorio@gea.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"yolanda.llorca@hilton.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"anabela.amaral@fresenius-kabi.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"joana.loureiro@fresenius-kabi.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"marta.lesovska@hilton.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"yasmine.omar@medtronic.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"desiree.de.lannee@eon.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"sandra.schuck@eon.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"ulyana.dolgopolova@ubisoft.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"itzal.gutierrez@siemensgamesa.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"fabiola.giuliano@unicatt.it", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"gconti010@gmail.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"ingrid.rampersad@wolterskluwer.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"alexandra.kapaun@boehringer-ingelheim.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"barbara.gary@boehringer-ingelheim.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"anastasiia.tsubar@dxc.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"jnieder@borgwarner.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"begona.dorr@chep.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"patrizia.schoder@fortum.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"alina.lauinger@kit.edu", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"caterina.vendramini@airahome.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"kaousar.ampousalim@enterprise-ireland.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"heather.ahrens@ameritas.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"raquel.degav@uriach.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"natalia@drumelia.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"sustiwar@visa.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"heather.smith@csaa.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"m.gois@fugro.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"lucie.bernathova@deutsche-boerse.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"rachel.brady@glasgow.ac.uk", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"alena.dvorackova@eurowag.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"gabriela.tvrdikova@eurowag.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"artime.kica-dauti@tecan.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"simone.rueegg@tecan.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"zhyldyz.abdygulova@osce.org", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"lin.huang@zeiss.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"s.paschal@merus.nl", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"skye.ford@ubteam.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"anastasia.bruzzone@iit.it", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"luciano.garcia@arup.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"erika.placci@panasonic.aero", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"julia.vonschulman@op.fi", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"info@officedynamics.nl dfa.rykes", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"lena.bach.hansen@beumer.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"maciej.baranowski@o2.pl", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"charlie.stansfield@twinings.com", eventName: "4TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT HYBRID CONFERENCE" },
    { email:"alexey.ignatyev@bcfgroup.eu", eventName: "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE" },
    { email:"crystal_d_silva@baxter.com", eventName: "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE" },
    { email:"crystalnelita@gmail.com", eventName: "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE" },
    { email:"nvelevska@alkaloid.com.mk", eventName: "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE" },
    { email:"Moumouzas@verisfield.gr", eventName: "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE" },
    { email:"moumouzas@verisfield.gr", eventName: "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE" },
    // 5TH ANNUAL BTEL SUMMIT & EXHIBITION
    { email:"jennifer.boldt@accellerate.me", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"martna.kurejova@aesculap.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"sabine.schwarzkopf@aesculap.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"mercedes.salvador-silva@alcon.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Adrien.RAGUENES@macopharma.info", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"konrad.kotas@azenta.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Monika.Rousse@bd.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"craig.freeman@bibratoxadvice.co.uk", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"stephanie.dorschel@biontech.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"lnetchacovitch@bvimedical.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"rona.middlemiss@chorleyconsulting.co.uk", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"fiona.chapman@chorleyconsulting.co.uk", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Phil.clay@chorleyconsulting.co.uk", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"hardeep.singh@cormica.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"luminita.moraru@met.uk.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"krista.lumsden-friedli@cytiva.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"elena.garrido@daliaglobal.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"tanja.rademann@draeger.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"simon@edgetoxicology.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Dino.Alferi@ellacs.eu", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"davideangelini@eurofins.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"SimoneCarrara@eurofins.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"IvanaCustic@eurofins.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"ValentinaFerron@eurofins.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"BenitoDiLauro@eurofins.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"d.matak@ebi.bio", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"g.milward@gamahealthcare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"michael.shur@gerresheimer.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"samer.joudieh@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"jc.ansart@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Marina.Simon@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Christian.Poinsot@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"stefan.heck@intertek.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"mark.zurbruegg@intertek.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"abenavides@ivascular.global", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"MCostante@knoell.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"gberno@knoell.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"laia.salvado@grifols.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Bello_William@hotmail.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"lukas.mogler@lonza.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Maxime.Pierre@macopharma.info", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Sophie.Clewlow@mhra.gov.uk", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"mauro.bordin@mxns.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"enrico.nieddu@mxns.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"vlevelut@namsa.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"jleneindre@namsa.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"kvanderplas@nelsonlabs.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"ejamez@nelsonlabs.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"hdevos@nelsonlabs.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Werner.Desiante@niutec.ch", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"elmedina.shimaj@stevanatogroup.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"laura.martikainen@orionpharma.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Paola.Lucero@hartmann.info", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"julienne.dietz@hartmann.info", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"eloise.lebaudy@hartmann.info", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"m.kontaxaki@rni-consulting.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"spilewski@rqmplus.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"skowalski@rqmplus.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"cjoray@septodont.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"adenizot@septodont.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"claudia.heldt@sfm.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"andreas.nixdorf@sgs.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Martin.Liebig@sgs.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"dominika.surdyk@shorlaoncology.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Tugba.BilgicTune@sonova.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Valerio.Vitelli@stryker.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"karthik.nair@summit-medical.co.uk", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"Elsa.Marmaras@wellspect.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"jason.creasey@maveneandl.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"ahussain@broughton-group.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
        { email:"aHutchinson@broughton-group.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"david.fitzmiller@wuxiapptec.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Rita.Beel@am-labor.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"TLourenco@dalton.c", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"alexey.ignatyev@bcfgroup.eu", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"mike.ludlow@element.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"bukkymarg@googlemail.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Natarajan.Bhanu@mnk.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"natarajan.bhanu@mnk.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"stephanie.buedenbender@sfm.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Verena.Schreier@ypsomed.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"putl@dgs-diagnostics.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"kai.zwingenberger@cslbehring.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"giampaoloberno@yahoo.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"margherita.monico@mxns.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"paolopescio@eurofins.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"nadine.ritterskamp@metecon.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"sylvia_koch@baxter.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"Manolina.Kontaxaki@productlife-group.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"celoise.lebaudy@hartmann.info", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"martina.kurejova@aesculap.de", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"lucie.buguet@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"alexandre.peroche@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"general@bcfgroup.eu", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"maria.zober@stryker.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"communication@groupeicare.com", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"priscila.pelaezromero@gc.dental", eventName: "5TH ANNUAL BTEL SUMMIT & EXHIBITION" },
    { email:"alexey.ignatyev@bcfgroup.eu", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"tazka@dubochem.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"shejbal@dubochem.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"info@dubochem.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"amanda.floriani@unilever.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"mohan.wadekar@jiahua.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"sales@bbaecotech.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "peter.lake@azelis.co.uk", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "konrad.grygiel@evonik.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "pmartinez@acmarca.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "eroma@acmarca.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "andreas.maroudas@eurochartiki.gr", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "jenna@ecos.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "kamila.pyra@mibellegroup.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "s.kieloch@munzing.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "m.riservato@munzing.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "emilie.garneau@sanimarc.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "SClark@dominant.com.au", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "dknowles@dominant.com.au", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "broca@persan.es", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "nancy.mamzour@kraton.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "massimo.bricchi@kuraray.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "mohan.wadekar@jiahua.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "w.kilian@knu.ac.kr", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "emmapearson@evyap.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "dr.wolfgang.marschke@hydrite.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "mattia.rusalen@electrolux.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "ahirwal.d@pg.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "sven.kirsch@vantagegrp.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "lina.gutierrez@vantagegrp.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "maria-fernanda.vacas@unilever.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "scjuanfelipe@gmail.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "samuele.verbano@omya.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "amanda.floriani@unilever.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "alex.miller@solentgroup.co.uk", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "walter.schenck@clorox.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "andrew.gowty@naturesorganics.com.au", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "KBukasa@10FOLD.ca", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "WLu@10foldgroupinc.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "roel@frames.consulting", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "monica.diaz@spb.es", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "michel.lim.08@gmail.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email: "virginia.sanchez@cepsachemicals.com", eventName: "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE" },
    { email:"alexey.ignatyev@bcfgroup.eu", eventName: "INTERNATIONAL HRD SUMMIT" },
    { email: "rien.van.beek@swinkelsfamilybrewers.nl", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "mtamai@loacker.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "rene.summeren@frieslandcampina.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Mieke.Poelvoorde@frieslandcampina.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Emmy.Nitert-van-Schijndel@dsm-firmenich.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Erica.Buil@dsm-firmenich.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Tim.Pessers@gea.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "pankaj.maharjan@agriculture.vic.gov.au", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "cassandra.walker@agriculture.vic.gov.au", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "claus-friso.gellermann@nordzucker.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "jonathan.schuh@nordzucker.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "erica@onedegreeorganics.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "romain.huber@ecotone.bio", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "nehal.keshwala@fresenius-kabi.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Bjoern.Behne@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "edita.mazoniene@roquette.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "supplies@conditofoods.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Renata.Nascimento@seara.com.br", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "kamila.abelha@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Lydia.Galliet@endori.de", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "axel.dewith@endori.de", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "voievidka@global.saraya.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "swen.skrzipietz@endori.de", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "friedjof.habel@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "niels@meatless.nl", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "tatjana@meatless.nl", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "lauren.ellis@diageo.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "chanchal.narain-baggott@diageo.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "nicole.buschmann@de.storck.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "veganfoodlaw@gmail.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "mdc@forwardfoodlaw.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Roland.Snel@adm.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "jens.tuider@proveg.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "william_angleys@barry-callebaut.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "joana.campos@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "KML@foodlawscience.eu", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "guglielmo.ferracin@gea.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Tim.Pessers@gea.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "soyman@puratos.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "esteban@food.ku.dk", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "anna.kuznetcova@bcfgroup.eu", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Fatemeh.Piroozian@IFF.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "mtamai@loacker.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "rene.summeren@frieslandcampina.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Mieke.Poelvoorde@frieslandcampina.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Emmy.Nitert-van-Schijndel@dsm-firmenich.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Erica.Buil@dsm-firmenich.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Tim.Pessers@gea.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "pankaj.maharjan@agriculture.vic.gov.au", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "cassandra.walker@agriculture.vic.gov.au", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "claus-friso.gellermann@nordzucker.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "jonathan.schuh@nordzucker.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "erica@onedegreeorganics.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "romain.huber@ecotone.bio", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "nehal.keshwala@fresenius-kabi.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Bjoern.Behne@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "edita.mazoniene@roquette.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "supplies@conditofoods.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Renata.Nascimento@seara.com.br", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "kamila.abelha@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "Lydia.Galliet@endori.de", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "axel.dewith@endori.de", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "voievidka@global.saraya.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "swen.skrzipietz@endori.de", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "friedjof.habel@tetrapak.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "niels@meatless.nl", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "tatjana@meatless.nl", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "lauren.ellis@diageo.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "chanchal.narain-baggott@diageo.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "nicole.buschmann@de.storck.com", eventName: "INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT" },
    { email: "ulyana.dolgopolova@ubisoft.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "victoria.tairova@osce.org", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "viktoria.tairova@osce.org", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "karine.vandevalle@allianz.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "nicole.matysek@allnex.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "julie.monin@swift.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "caroline.binard@swift.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "kristin.loeblich@swarovski.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "alexia@envoker.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Shana.Lawler@paessler.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "fabiola.giuliano@unicatt.it", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "gconti010@gmail.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "aizada.khalilova@osce.org", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "pia.schwartz@bestseller.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "anastasiia.tsubar@dxc.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "jennamaria.pennanen@hoas.fi", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "nataliapjesus44@gmail.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Laura.Findlay@fcdo.gov.uk", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "elliott.batchelor@fcdo.gov.uk", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "celina.rellahan@fiskars.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "isabel.campos@informadb.pt", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "stephanie.aitbali@natixis.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "micaela.grilli@ecb.europa.eu", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "gianluca.commisso@ecb.europa.eu", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Rachel.Brady@glasgow.ac.uk", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "idalecia.agostinho@pestana.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Francesca.Montermini@wuerth.it", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "katri.vuorinen@fortum.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "patrizia.schoder@fortum.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "rute.teixeira@phcsoftware.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Francesca.Vidali29@gmail.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "francesca.vidali@reithera.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Valentina.DiRella@reithera.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "noemi.gonzalez@arup.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "izabele.suskeviciute@matthey.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "evy.loan@yahoo.fr", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "mvictoviana@gmail.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "monique@supmo.nl", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "MontserratSousa@bankofscotland.co.uk", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "Montserrat.coachingforsuccess@gmail.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "olga.vartanova@stryker.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "david@svobodavid.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "ml@mariellelegair.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "eafer@pandora.net", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "briony_baxter@mckinsey.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "mia.hope@mastercard.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "anna.kuznetcova@bcfgroup.eu", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "crellahan@gmail.com", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT" },
    { email: "pyw@spyro-soft.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "einkauf@dspace.de", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "lippmann@3d-micromac.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "lsadovnik@holoptic.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "Hans.Friberg@saabgroup.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "tommy.sunden@saabgroup.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "dominik.becker@preh.de", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "Matthew.Goodavish@infineon.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "stacy.wu@omdia.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "kb@displaylabor.de", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "aoy20@cam.ac.uk", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "gomathis525@gmail.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "sheetal.kondhare12@gmail.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "rohanrepale@gmail.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "sangyu@gmail.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "mawad9@gmail.com", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "alexey.ignatyev@bcfgroup.eu", eventName: "AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE" },
    { email: "alexey.ignatyev@bcfgroup.eu", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "ulf.krueger@pulmotree.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "ehrhardc@tcd.ie", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "jonathan.mulpas@aptar.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "kai.berkenfeld@boehringer-ingelheim.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Yannick.baschung@solvias.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "arturocubi@berryglobal.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "pawel.bijos@teva.pl", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Ross.blezard@DFEPharma.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "kalpitarsn@gmail.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Shayoni.x.dutta@gsk.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "simone.carneiro@cup.uni-muenchen.de", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "rassa.pegahi@upsa-ph.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "hvgandhi21@gmail.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "pierre.vitaloni@lonza.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "scodling@containment-technology.co.uk", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "valeriasesana@berryglobal.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "maraputignano@berryglobal.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "marcofranza@berryglobal.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "carolina.dantas@pulmotree.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "e.canipa@nanopharm.co.uk", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "k.alzahabi@nanopharm.co.uk", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "joalee@hikma.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "shabbir.mostafa@siegfried.ch", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "bart.rimez@secoya-tech.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "I.Knowles@pharmidex.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "jakub.strzemieczny@polpharma.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "lee.wood@med-hf.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "ciaran.james@med-hf.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "julie.delvaux@unither-pharma.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "stefano.balestrini@apr.ch", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Marco.Colombo@apr.ch", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "thomas.wocko@filtrationgroup.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "victoria@inhalytic.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "rupert@inhalytic.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Satoshi2_Yamamoto@terumo.co.jp", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "rs.verona@newchemspa.it", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "herudek@enantis.com", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "bianca.cavalli@rxpack.it", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "anna.kuznetcova@bcfgroup.eu", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "ethelchanga@gmail.com", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "helen.day22@nhs.net", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "sara.dipaola@dedalus.eu", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "nehajanvi.bharti@gmail.com", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "outi.Parkkonen@hus.fi", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "jgfernan@gmail.com", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "salla.lindblad-palo@hel.fi", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "charlie.moore@spirehealthcare.com", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "bilyanakrasteva.bk@gmail.com", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "anna.Roynon@wales.nhs.uk", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "sebastian.Drews@balgrist.ch", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "susan.Palmer@wales.nhs.uk", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "kerstin.steffansson-ohman@hus.fi", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "arja.sihvo@hus.fi", eventName: "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE" },
    { email: "alexey.ignatyev@bcfgroup.eu", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "kszukup@mol.hu", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "antoni.faeth@magna.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "axel.bauer@aptar.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "marc.wilson@chep.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "t.kroner@mkt-gmbh.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "s.kirchner@mkt-gmbh.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "nicolas.esselin@sk.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "smatikova@novacel-solutions.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "jaroslav.hlavac@ravago.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "LEILA.MEISTERTZHEIM@PLASTICATSEA.COM", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "sumant@cpfl.co.in", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "laurie.ruas@plasticatsea.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "marc.triepels@intertek.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "ilse.hindrikx@intertek.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "lamatz@arsol-aromatics.de", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "b.richters@enespa.eu", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "eappelman@adurocleantech.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "tderossi@sirmax.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "adyck@adurocleantech.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Marta.Ochalek@Altana.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "peter.staelens@saint-gobain.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "emilie.vedelblankschon@altana.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "augthun@arsol-aromatics.de", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Inna.Dewald@cflex.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "ellis.vandam@braskem.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "klja@topsoe.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Urvashi.Athavale@bluealp.nl", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "marra.g@fatergroup.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Meneghelli.m@fatergroup.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "alan.luke@cgtower.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Richard.Bale@spglobal.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Luca.Raffellini@spglobal.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "c.hentschel@uvex.de", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "stephan.roest@borealisgroup.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "UKPlasticsPact@wrap.ngo", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "robert.kunzmann@acbiode.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "julia.alonzo@jdecoffee.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Erik.Gubbels@basf.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "outi.teras@neste.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "p.lackner@ig-iut.at", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "saschabloemhoff@gmail.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "egashira.yuka@eneos.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Kubota.Shun@eneos.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "santiago.viozquez@greene.es", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "richard.hermann@imcd.de", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "psiladie@struktol.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "annemariede.moei@alfalaval.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Hans_Ridderikhoff@cargill.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "levyadv@seznam.cz", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "mleborgne@novacel-solutions.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "ryousuke-ozawa@ma.dic.co.jp", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "antonio.bassi@saipem.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "rupert.schnell@evonik.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Joeri.dieltjens@sulzer.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "danielly.cortes@stellantis.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "chaim.waibel@basf.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "beate.edl@omv.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "paul.r.hampson@outlook.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Werner.BOSMANS@ec.europa.eu", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "Dimitrios_Vgenopoulos@cargill.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "seda.cakirbenthem@teijinaramid.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "karber@aestechnik.de", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "rsanchezm@repsol.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "i.radic@mas-austria.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email: "alain.choquet@motherson.com", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION" },
    { email:"alexey.ignatyev@bcfgroup.eu", eventName: "6TH ANNUAL BTEL SUMMIT & EXHIBITION" },


];

export default allowedEmails;
