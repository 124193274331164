import React from 'react';
import { pdf, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import pdflogo from '../assets/images/logo2.png'
import ArialMT from '../assets/fonts/arial/ArialMT.ttf'
import ArialBold from '../assets/fonts/arial/Arial-BoldMT.ttf'
import getDiscountFromPromo from './promocode/promoHelper.js';

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

Font.register({
  family: 'Arial',
  fonts: [
    { src: ArialMT },
    { src: ArialBold, fontWeight: "bold" }
  ]
});

// Определяем стили
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Arial',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 10,
  },
  subtotalContainer: {
    backgroundColor: '#f8f8f8',
  },
  // Стили для хедера
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: {
    width: '60%',
  },
  headerRight: {
    width: '40%',
    alignItems: 'flex-start',
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 15,
    color: 'white',
  },
  headerInfo: {
    fontSize: 10,
    marginBottom: 4,
    color: 'white',
  },
  headerLabel: {
    fontSize: 10,
    marginBottom: 4,
    color: 'white',
    marginTop: 10,
  },
  divider: {
    borderRightWidth: 1,
    borderRightColor: 'white',
    height: '100%',
    marginRight: 15,
  },
  supplierTitle: {
    fontSize: 10,
    color: '#FFA500', // Оранжевый цвет для слова "Supplier"
    marginBottom: 4,
  },
  // Стили для информации о клиенте
  customerSection: {
    backgroundColor: '#f5f5dc', // светло-бежевый
    padding: 20,
    borderWidth: 1,
    borderColor: '#ccc',
    marginHorizontal: 20,
    marginVertical: 5,
  },
  customerTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  customerCompany: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  customerInfo: {
    fontSize: 10,
    marginBottom: 5,
  },
  // Стили для таблицы продуктов
  tableContainer: {
    marginHorizontal: 20,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    backgroundColor: '#f5f5dc', // светло-бежевый
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  tableHeaderText: {
    padding: 10,
    fontWeight: 'bold',
    fontSize: 10,
  },
  tableCell: {
    padding: 10,
    fontSize: 10,
  },
  tableCol1: {
    width: '40%',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  tableCol2: {
    width: '20%',
    textAlign: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  tableCol3: {
    width: '20%',
    textAlign: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  tableCol4: {
    width: '20%',
    textAlign: 'right',
  },
  subtotalRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  subtotalLabel: {
    width: '80%',
    padding: 10,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 'bold',
    color: '#2c3e50',
  },
  subtotalValue: {
    width: '20%',
    padding: 10,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 'bold',
  },
  // Стили для информации об оплате
  paymentSection: {
    marginHorizontal: 20,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  paymentTitle: {
    backgroundColor: '#f5f5dc', // светло-бежевый
    padding: 10,
    fontWeight: 'bold',
    fontSize: 10,
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    color: '#2c3e50',
  },
  paymentColumns: {
    flexDirection: 'row',
  },
  paymentLeft: {
    width: '50%',
    padding: 10,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  paymentRight: {
    width: '50%',
    padding: 10,
  },
  paymentLabel: {
    fontSize: 10,
    marginBottom: 5,
  },
  paymentValue: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  totalPrice: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
    color: '#2c3e50',
  },
  totalPriceValue: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 5,
    textAlign: 'center',
  },
  dividerLine: {
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    marginVertical: 10,
  },
  logo: {
    width: 25,
    height: 25,
    marginTop: 30,
  },
  notesSection: {
    marginHorizontal: 20,
    marginTop: 10,
    marginBottom: 20,
  },
  notesTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  notesText: {
    fontSize: 10,
  }
});

// Функция для генерации содержимого PDF
const generatePDFContent = (bank, invoiceNum, discountedPrice, discount) => {
  const today = new Date();
  const formattedToday = formatDate(today);

  const dueDate = new Date(today);
  dueDate.setDate(today.getDate() + 10);
  const formattedDueDate = formatDate(dueDate);

  return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Заголовок с информацией об инвойсе и поставщике */}
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Text style={styles.headerTitle}>Invoice</Text>
              <Text style={styles.headerInfo}>Number of Invoice: {invoiceNum}</Text>
              <Text style={styles.headerInfo}>Date of Issue: {formattedToday}</Text>
              <Text style={styles.headerInfo}>Due Date: {formattedDueDate}</Text>
            </View>
            <View style={styles.headerRight}>
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.divider} />
                <View style={{ marginRight: 10 }}>
                  <Image src={pdflogo} style={styles.logo} />
                </View>
                <View>
                  <Text style={styles.supplierTitle}>Supplier</Text>
                  <Text style={styles.headerInfo}>The Winners s.r.o.</Text>
                  <Text style={styles.headerInfo}>IČO: 17156530</Text>
                  <Text style={styles.headerInfo}>Address: Kubanske Namesti 11,</Text>
                  <Text style={styles.headerInfo}>Praha 10, 110 00</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Информация о клиенте */}
          <View style={styles.customerSection}>
            <Text style={styles.customerTitle}>Customer:</Text>
            <Text style={styles.customerCompany}>{bank.company}</Text>
            <Text style={styles.customerInfo}>{bank.address}</Text>
            <Text style={styles.customerInfo}>{bank.city}</Text>
            <Text style={styles.customerInfo}>IČO: {bank.vat.startsWith('CZ') ? bank.vat.substring(2) : bank.vat}</Text>
            <Text style={styles.customerInfo}>VAT</Text>
          </View>

          {/* Таблица продуктов */}
          <View style={styles.tableContainer}>
            {/* Заголовок таблицы */}
            <View style={styles.tableHeader}>
              <View style={styles.tableCol1}>
                <Text style={[styles.tableHeaderText, { textAlign: 'center' }]}>Delivery name</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableHeaderText}>Quantity unit</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableHeaderText}>Unit price</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableHeaderText}>Total</Text>
              </View>
            </View>

            {/* Строка с продуктом */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>IN-PERSON | {bank.conferenceName}</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>1</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>{bank.ticket.price}</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>{bank.ticket.price}</Text>
              </View>
            </View>

            {/* Строка с подытогом */}
            <View style={styles.subtotalRow}>
              <View style={{ width: '80%' }}>
                <Text style={{ textAlign: 'right', fontWeight: 'bold', padding: 10, fontSize: 10 }}>Subtotal</Text>
              </View>
              <View style={{ width: '20%', backgroundColor: '#f8f8f8' }}>
                <Text style={{ textAlign: 'right', fontWeight: 'bold', padding: 10, fontSize: 10 }}>{bank.ticket.price}</Text>
              </View>
            </View>

            {/* Строка с промокодом */}
            <View style={styles.subtotalRow}>
              <View style={{ width: '80%' }}>
                <Text style={{ textAlign: 'right', fontWeight: 'bold', padding: 10, fontSize: 10, color: '#2c3e50' }}>Promocode</Text>
              </View>
              <View style={{ width: '20%', backgroundColor: '#f8f8f8' }}>
                <Text style={{ textAlign: 'right', fontWeight: 'bold', padding: 10, fontSize: 10 }}>
                  {bank.promo && discount ?
                      (discount.type === 'fixed' ?
                              discount.value :
                              Math.round((bank.ticket.price * discount.value / 100))
                      ) :
                      '0'
                  }
                </Text>
              </View>
            </View>

            {/* Строка с итоговой суммой */}
            <View style={styles.subtotalRow}>
              <View style={{ width: '80%' }}>
                <Text style={{ textAlign: 'right', fontWeight: 'bold', padding: 10, fontSize: 10, color: '#2c3e50' }}>Total Price</Text>
              </View>
              <View style={{ width: '20%', backgroundColor: '#f8f8f8' }}>
                <Text style={{ textAlign: 'right', fontWeight: 'bold', padding: 10, fontSize: 10 }}>{discountedPrice}</Text>
              </View>
            </View>
          </View>

          {/* Информация об оплате */}
          <View style={styles.paymentSection}>
            <Text style={styles.paymentTitle}>Payment Information</Text>

            <View style={styles.paymentColumns}>
              <View style={styles.paymentLeft}>
                <Text style={styles.paymentLabel}>Account No. 2191438273/0800</Text>
                <Text style={styles.paymentValue}>IBAN: CZ71 0800 0000 0021 9143 8273</Text>
                <Text style={styles.paymentValue}>SWIFT: GIBACZPX</Text>
                <Text style={styles.paymentLabel}>Symbol variable: {invoiceNum}</Text>
                <Text style={styles.paymentLabel}>Constant: 0308</Text>
              </View>

              <View style={styles.paymentRight}>
                <Text style={styles.paymentLabel}>Payment:</Text>
                <Text style={styles.paymentValue}>Bank transfer</Text>
                <View style={styles.dividerLine} />
                <Text style={styles.totalPrice}>TOTAL PRICE</Text>
                <Text style={styles.totalPriceValue}>{discountedPrice} EUR</Text>
              </View>
            </View>
          </View>

          {/* Notes section */}
          <View style={styles.notesSection}>
            <Text style={styles.notesTitle}>Notes:</Text>
            <Text style={styles.notesText}>Please note this in case of non-compliance with the due date stated on the invoice we may charge you for statutory interest for delayed payment.</Text>
            <Text style={styles.notesText}>*cancellation policy you can find on our website www.bcf-events.com</Text>
          </View>
        </Page>
      </Document>
  );
};

// Основная функция для создания инвойса
const InvoicePDF = (bank, invoiceNum) => {
  // Проверка входных параметров
  if (!bank || !invoiceNum) {
    console.error('Неверные входные параметры');
    return null;
  }

  // Получаем скидку из промокода
  const discountInfo = getDiscountFromPromo(bank.promo, bank.conferenceName, bank.ticket.name);

  // Рассчитываем сумму с учетом скидки
  let discountedPrice = parseFloat(bank.ticket.price);

  if (discountInfo && discountInfo.type === "fixed") {
    discountedPrice -= discountInfo.value;
  } else if (discountInfo && discountInfo.type === "percent") {
    const discountPercent = discountInfo.value / 100;
    const discountAmount = discountedPrice * discountPercent;
    discountedPrice -= discountAmount;
  }

  // Форматируем цену
  const formattedPrice = discountedPrice.toFixed(0);

  try {
    // Генерация PDF
    const blob = pdf(generatePDFContent(bank, invoiceNum, formattedPrice, discountInfo)).toBlob();
    return blob;
  } catch (error) {
    console.error('Ошибка во время генерации PDF:', error);
    return null;
  }
};

export default InvoicePDF;