import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import "magnific-popup";
import Modal from "../../components/Modal";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import api from "../../api/endpoints/events.js";
import { NotificationManager } from "react-notifications";
import { ThreeDots } from "react-loader-spinner";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { Paper, Grid, Typography, List, ListItem, ListItemText, Box, ListItemIcon } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";






function Sponsorship() {
  const [allConferences, setAllConferences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const swiperRef = useRef(null);

  const agendaInitState = {
    header: "Sponsorship Pharmaceutical",
    fullName: "",
    email: "",
    phone: "",
    job: "",
    company: "",
    address: "",
    city: "",
    vat: "",
    eventName: "",
    packages: "",
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const responsiveChartHeight = isMobile ? 200 : 250;
  const chartOuterRadius = isMobile ? 80 : 100;
  const [agenda, setAgenda] = useState(agendaInitState);
  const [currentType, setCurrentType] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleAgenda = (event) => {
    const { name, value } = event.target;
    setAgenda((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const capitalizedEventNames =  allConferences?.map((event) =>
      event.eventName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  );
  console.log(capitalizedEventNames);
  const getAllEvents = async () => {
    try {
      const { data } = await api.get("/Event/get-all-events?pageNumber=1&pageSize=100");
      console.log("Data from API:", data);
      setAllConferences(data.items);
       const sortedConferences = data.items.sort((a, b) => {
          const dateA = new Date(a.endDate);
          const dateB = new Date(b.endDate);
          return dateA - dateB;
        });
      setAllConferences(sortedConferences);
      
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  const postMessageToSlack = async (e, type) => {
    e.preventDefault();
    let url = '';
    if (window.location.hostname === 'localhost') {
        //url = 'https://localhost:7264/';
        url = 'https://api.bcf-events.com/';
    }
    if (window.location.hostname === 'green-desert-021865b10.3.azurestaticapps.net' 
        || window.location.hostname === 'brave-dune-00ae5aa10.3.azurestaticapps.net'
        || window.location.hostname === 'new.bcf-events.com'
        || window.location.hostname === 'bcf-events.com'
    ) {
        url = 'https://api.bcf-events.com/';
    }

    try {
      setIsLoading(true);
      setAgenda(agendaInitState);
      let perfectName = {
        "Header": agenda.header,
        "Full Name": agenda.fullName,
        "Email": agenda.email,
        "Phone": agenda.phone,
        "Job Title": agenda.job,
        "Company": agenda.company,
        "Company Address": agenda.address,
        "City": agenda.city,
        "Vat": agenda.vat,
        "Conference": agenda.eventName,
        "Package": agenda.packages,
      };
      const response = await axios.post(`${url}User/slack/${type}`, perfectName);
      console.log('response from endpoint: ', response);
      setIsModalOpen(false);
      NotificationManager.success('Thank you for your request!\n', 'Success', 5000);
      setAgenda(agendaInitState);
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending message to Slack:", error);
      setIsLoading(false);
    }
  };

  const changeImage = (event) => {
    const img = event.target;
    img.setAttribute("data-original-src", img.getAttribute("src"));
    img.setAttribute("src", img.getAttribute("data-hover-src"));
  };

  const restoreImage = (event) => {
    const img = event.target;
    img.setAttribute("src", img.getAttribute("data-original-src"));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (type, packages) => {

    setAgenda((prevState) => ({
      ...prevState,
      packages: packages, //
    }));

    setCurrentType(type);
    setIsModalOpen(true);

    // document.querySelector(".header").style.zIndex = "1";
    // document.querySelector("body").style.overflow = "hidden";
  };
  useEffect(() => {
    if (isModalOpen) {
      // Logic for when the modal is open will go here
    }
  }, [isModalOpen]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // document.querySelector(".header").style.zIndex = "3";
    // document.querySelector("body").style.overflow = "auto";
  };

  // const testBtn = () => {
  //   NotificationManager.success('Thank you for your registration!\n' +
  //       'The invoice has been sent to your email.', 'Success', 3000);
  // }

  useEffect(() => {
    getAllEvents();
    window.scrollTo(0, 0);
  }, []);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5px'
  };
  const sectionStyle = {
    marginTop: '25px'
  };
  const paginationStyle = {
    marginTop: '20px', // Добавим немного отступа сверху
    textAlign: 'center' // Центрируем точки пагинации
  };

  const spanStyle = {
    display: 'block',
    width: '50%',
    height: '2px',
    backgroundColor: '#1089f1'
  };

  const swiperSlideStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '500px'
  };

  const imgStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    pointerEvents: 'none' // Убирает возможность кликать на фотографии
  };

  const mainBackgroundStyle = {
    backgroundColor: '#e6f7ff',
    color: '#000000',
    padding: '20px',
    width: '100%',
    minHeight: '50vh',
    boxSizing: 'border-box'
  };

  const paperStyle = {
    backgroundColor: '#ffffff',
    color: '#3f51b5',
    padding: '20px',
    margin: '10px 0',
    minHeight: '300px',
    boxSizing: 'border-box'
  };

  const chartContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box'
  };

  const listContainerStyle = {
    marginTop: '20px'
  };

  const targetAudienceStyle = {
    boxSizing: 'border-box'
  };

  const listIconStyle = {
    minWidth: 'auto',
    marginRight: '8px',
    color: '#4285F4'
  };

  const data = [
    { name: "Service Providers", value: 35, fill: "#8884d8" },
    { name: "OEM", value: 35, fill: "#83a6ed" },
    { name: "Suppliers and Distributors", value: 15, fill: "#8dd1e1" },
    { name: "End Users and Consumers", value: 10, fill: "#82ca9d" },
    { name: "Regulatory & Compliance Officers", value: 5, fill: "#a4de6c" }
  ];


  return (
    <div>
      <Header />
      <main>
        <section>
          <div className="main-container">
            <div className="sponsorship-background"></div>
            <div className="background-laptop"></div>
            <div className="background-sponsorship"></div>
            <div className="background-block">
              <div className="container">
                <div className="breadcrumbs-about">
                  <ul>
                    <li className="andThisStyle">
                      <Link className="conference-link add-link-breadcrumbs" to="/">
                        MAIN
                      </Link>
                    </li>
                    <li className="thisStyle">SPONSORSHIP</li>
                  </ul>
                  <Breadcrumbs />
                </div>
                <div>
                  <div className="sponsorship-block">
                    <h2 className="about-title">SPONSORSHIP</h2>
                    <span
                      className="line-title"
                      style={{ bottom: "-10px" }}
                    ></span>
                    <span
                      className="line-mobile"
                      style={{ bottom: "-10px" }}
                    ></span>
                  </div>
                  {/*<button onClick={testBtn}>Тестовая кнопка</button>*/}
                  <p className="about-description">
                    We work our best to present your company with the
                    opportunity to meet its targets as well as make the
                    investment worth it. Therefore, we are open to your ideas
                    and demands and could accommodate them at its very best. All
                    the packages below are customizable and can be repurposed to
                    the targeted goals of your company.
                    <br className="br-about" />
                    <br className="br-about" />
                    Our sponsorship bundles are designed for your company’s
                    brand promotion through media and on site advertising,
                    meeting your target audience and non-competitor companies to
                    increase sales as well as establish long-lasting business
                    connections. They are also designed exclusively to suit your
                    company expectations of our conferences as well as financial
                    capabilities. We want our sponsors to have maximum freedom
                    of choice and possibilities with BCF and to take the maximum
                    outcome for your corporate success.
                  </p>
                </div>
                <div className="bottom-arrow"></div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Box style={mainBackgroundStyle}>
            <Typography variant="h4" gutterBottom style={{ color: '#000000', fontWeight: 'bold' }}>
              Sponsorship Packages
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box style={targetAudienceStyle} className="chart-legend-container">
                  <Paper style={paperStyle}>
                    {/* Блок с диаграммой */}
                    <Box style={chartContainerStyle} className="chart-container">
                      <Typography
                          variant="h6"
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            marginBottom: isMobile ? 8 : 16,
                          }}
                      >
                        Target Audience
                      </Typography>
                      <ResponsiveContainer width="100%" height={responsiveChartHeight}>
                        <PieChart>
                          <Pie
                              data={data}
                              dataKey="value"
                              nameKey="name"
                              cx="50%"
                              cy="50%"
                              innerRadius={isMobile ? 40 : 60}
                              outerRadius={isMobile ? 60 : 100}
                              fill="#8884d8"
                              paddingAngle={5}
                              labelLine={false}
                          >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend
                              align={isMobile ? "center" : "right"}
                              verticalAlign={isMobile ? "bottom" : "middle"}
                              layout={isMobile ? "horizontal" : "vertical"}
                              wrapperStyle={{
                                fontSize: isMobile ? "12px" : "14px",
                                marginTop: isMobile ? 8 : 0,
                              }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>

                    {/* Блок со списком Sponsorship Benefits */}
                    <Box style={listContainerStyle} className="legend-container">
                      <Typography
                          variant="h6"
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            marginTop: isMobile ? 8 : 16,
                          }}
                      >
                        Sponsorship Benefits
                      </Typography>
                      {/*
            xs={12} sm={6} означает:
            - на очень маленьких экранах (xs) ширина 12 из 12 (т.е. 100%)
            - начиная со sm (смартфоны в горизонтали / планшеты) ширина 6 из 12 (50%)
          */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <List dense style={{ padding: 0 }}>
                            <ListItem style={{ padding: isMobile ? "4px 0" : "8px 0" }}>
                              <ListItemIcon style={{ ...listIconStyle, minWidth: isMobile ? 24 : 32 }}>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                    <Typography variant={isMobile ? "body2" : "body1"}>
                                      Increased Brand Visibility
                                    </Typography>
                                  }
                              />
                            </ListItem>
                            <ListItem style={{ padding: isMobile ? "4px 0" : "8px 0" }}>
                              <ListItemIcon style={{ ...listIconStyle, minWidth: isMobile ? 24 : 32 }}>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                    <Typography variant={isMobile ? "body2" : "body1"}>
                                      Lead Generation
                                    </Typography>
                                  }
                              />
                            </ListItem>
                            <ListItem style={{ padding: isMobile ? "4px 0" : "8px 0" }}>
                              <ListItemIcon style={{ ...listIconStyle, minWidth: isMobile ? 24 : 32 }}>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                    <Typography variant={isMobile ? "body2" : "body1"}>
                                      Thought Leadership
                                    </Typography>
                                  }
                              />
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <List dense style={{ padding: 0 }}>
                            <ListItem style={{ padding: isMobile ? "4px 0" : "8px 0" }}>
                              <ListItemIcon style={{ ...listIconStyle, minWidth: isMobile ? 24 : 32 }}>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                    <Typography variant={isMobile ? "body2" : "body1"}>
                                      Networking Opportunities
                                    </Typography>
                                  }
                              />
                            </ListItem>
                            <ListItem style={{ padding: isMobile ? "4px 0" : "8px 0" }}>
                              <ListItemIcon style={{ ...listIconStyle, minWidth: isMobile ? 24 : 32 }}>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                    <Typography variant={isMobile ? "body2" : "body1"}>
                                      Product Launch Platform
                                    </Typography>
                                  }
                              />
                            </ListItem>
                            <ListItem style={{ padding: isMobile ? "4px 0" : "8px 0" }}>
                              <ListItemIcon style={{ ...listIconStyle, minWidth: isMobile ? 24 : 32 }}>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                  primary={
                                    <Typography variant={isMobile ? "body2" : "body1"}>
                                      Competitive Edge
                                    </Typography>
                                  }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Box>
              </Grid>

              {/* Второй столбец с How Do You Get Involved */}
              <Grid item xs={12} md={6}>
                <Paper style={paperStyle}>
                  <Typography variant="h6" style={{ color: '#000000', fontWeight: 'bold' }}>
                    How Do You Get Involved?
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText
                          primary={
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                              Workshop
                            </Typography>
                          }
                          secondary="Gain exclusive access to a select group of attendees who are specifically interested in your company's products and services during your private workshop."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                          primary={
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                              Speaking Slot
                            </Typography>
                          }
                          secondary="Highlight your expertise and motivate a worldwide audience by sharing your story through engaging case study examples."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                          primary={
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                              Exhibition
                            </Typography>
                          }
                          secondary="Generate leads by setting up a stand, introduce your newest products, establish your presence, и draw the attention of top industry leaders."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                          primary={
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                              1:1 Meetings
                            </Typography>
                          }
                          secondary="Connect directly with potential clients, partners, and stakeholders, fostering valuable relationships and networking opportunities."
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>

          </Box>
        </section>
        <section>
          <div className="container">
            <h2 className="title-about title-packages">Packages</h2>
            <div className="line-container">
              <span className="line-about"></span>
            </div>
            <ul className="packages-container">
              <li className="packages-item">
                <h3 className="sponsor-title">SILVER</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">2800€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 1</li>
                  <li>Promo on Social Media</li>
                  <li>Logo and URL placement</li>
                  <li>Promo materials in the attendee&apos;s satchel</li>
                  <li>Video ads during the coffee break</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1/4 Page</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Silver")} className="sponsors-button">Become a sponsor</button>
              </li>
              <li className="packages-item">
                <h3 className="sponsor-title">GOLD</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">3800€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 2</li>
                  <li>Promo on Social Media</li>
                  <li>Logo and URL placement</li>
                  <li>Promo materials in the attendee&apos;s satchel</li>
                  <li>Video ads during the coffee break</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1/4 Page</li>
                  <li>Exhibition table/stand</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Gold")} className="sponsors-button">Become a sponsor</button>
              </li>
              <li className="packages-item">
                <h3 className="sponsor-title">PLATINUM</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">5500€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                {/*поправка на ветер*/}
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 3</li>
                  <li>Promo on Social Media</li>
                  <li>Logo and URL placement</li>
                  <li>Promo materials in the attendee&apos;s satchel</li>
                  <li>Video ads during the coffee break</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1/2 Page</li>
                  <li>Exhibition table/stand</li>
                  <li>Speaking slot</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Platinum")} className="sponsors-button">Become a sponsor</button>
              </li>
              <li className="packages-item">
                <h3 className="sponsor-title">DIAMOND</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">7500€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 4</li>
                  <li>Promo on Social Media</li>
                  <li>Logo and URL placement</li>
                  <li>Promo materials in the attendee&apos;s satchel</li>
                  <li>Video ads during the coffee break</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1 Full Page</li>
                  <li>Exhibition table/stand</li>
                  <li>Speaking slot + Workshop/Tour</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Diamond")} className="sponsors-button">Become a sponsor</button>
              </li>
              {/*<li className="packages-item item-extra-mobile">*/}
              {/*  <h3 className="sponsor-title">EXTRA SERVICES</h3>*/}
              {/*  <div className="line-container">*/}
              {/*    <span className="line-sponsorship"></span>*/}
              {/*  </div>*/}
              {/*  <p className="sponsor-price add-extra-price">FROM 199 €</p>*/}
              {/*  <div className="line-container">*/}
              {/*    <span className="line-sponsorship"></span>*/}
              {/*  </div>*/}
              {/*  <ul className="sponsors-list">*/}
              {/*    <li>*/}
              {/*      1 Extra Promo Material to be included in attendee satchel*/}
              {/*      +199€*/}
              {/*    </li>*/}
              {/*    <li>Coffee Break Sponsor (x3) + 499€</li>*/}
              {/*    <li>Lunch Sponsor (x2) + 699€</li>*/}
              {/*    <li>Dinner Sponsor (x1) + 699€</li>*/}
              {/*    <li>Chairman Slot +399€</li>*/}
              {/*    <li>Workshop (20 min) +599€</li>*/}
              {/*    <li>Speaking Slot +999€</li>*/}
              {/*    <li>Exhibition Table +599€</li>*/}
              {/*    <li>1:1 Partnering Meetings +599€</li>*/}
              {/*    <li>Exhibition Stand 3x2 +699€</li>*/}
              {/*  </ul>*/}
              {/*  <button className="sponsors-button">Become a sponsor</button>*/}
              {/*</li>*/}
            </ul>
          </div>
        </section>
        <section>
          <div className="container">
            <ul className="packages-container">
              <li className="packages-item">
                <h3 className="sponsor-title">EXHIBITOR</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">3000€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 1</li>
                  <li>Logo and URL placement</li>
                  <li>Promo materials in the attendee&apos;s satchel</li>
                  <li>Video ads during the coffee break</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1/4 Page</li>
                  <li>Exhibition table/stand</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Exhibitor")} className="sponsors-button">Become a sponsor</button>
              </li>
              <li className="packages-item">
                <h3 className="sponsor-title">KEYNOTE</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">3200€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                {/*поправка на ветер*/}
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 1</li>
                  <li>Logo and URL placement</li>
                  <li>Promo on social media</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1/4 Page</li>
                  <li>Speaking Slot (30 minutes)</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Platinum")} className="sponsors-button">Become a sponsor</button>
              </li>
              <li className="packages-item">
                <h3 className="sponsor-title">WORKSHOP</h3>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                <p className="sponsor-price">3200€</p>
                <p className="sponsor-description">
                  Price for 2-days offline event
                </p>
                <div className="line-container">
                  <span className="line-sponsorship"></span>
                </div>
                {/*поправка на ветер*/}
                <ul className="sponsors-list">
                  <li>Number of delegate passes - 1</li>
                  <li>Logo and URL placement</li>
                  <li>Promo materials in the attendee&apos;s satchel</li>
                  <li>List of Attendees</li>
                  <li>Advert in agenda: 1/4 Page</li>
                  <li>Workshop</li>
                </ul>
                <button onClick={() => handleOpenModal("agenda", "Platinum")} className="sponsors-button">Become a sponsor</button>
              </li>
              {/*<li className="packages-item item-extra-mobile">*/}
              {/*  <h3 className="sponsor-title">EXTRA SERVICES</h3>*/}
              {/*  <div className="line-container">*/}
              {/*    <span className="line-sponsorship"></span>*/}
              {/*  </div>*/}
              {/*  <p className="sponsor-price add-extra-price">FROM 199 €</p>*/}
              {/*  <div className="line-container">*/}
              {/*    <span className="line-sponsorship"></span>*/}
              {/*  </div>*/}
              {/*  <ul className="sponsors-list">*/}
              {/*    <li>*/}
              {/*      1 Extra Promo Material to be included in attendee satchel*/}
              {/*      +199€*/}
              {/*    </li>*/}
              {/*    <li>Coffee Break Sponsor (x3) + 499€</li>*/}
              {/*    <li>Lunch Sponsor (x2) + 699€</li>*/}
              {/*    <li>Dinner Sponsor (x1) + 699€</li>*/}
              {/*    <li>Chairman Slot +399€</li>*/}
              {/*    <li>Workshop (20 min) +599€</li>*/}
              {/*    <li>Speaking Slot +999€</li>*/}
              {/*    <li>Exhibition Table +599€</li>*/}
              {/*    <li>1:1 Partnering Meetings +599€</li>*/}
              {/*    <li>Exhibition Stand 3x2 +699€</li>*/}
              {/*  </ul>*/}
              {/*  <button className="sponsors-button">Become a sponsor</button>*/}
              {/*</li>*/}
            </ul>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="addition-container">
              {/*<div className="addition-service">*/}
              {/*  <h3 className="addition-title">EXTRA SERVICES</h3>*/}
              {/*  <div className="line-container">*/}
              {/*    <span className="line-about"></span>*/}
              {/*  </div>*/}
              {/*  <div className="addition-list">*/}
              {/*    <ul className="addition-service-first addition-border-class">*/}
              {/*      <li>*/}
              {/*        1 Extra Promo Material to be included in attendee satchel*/}
              {/*        +199€*/}
              {/*      </li>*/}
              {/*      <li>Coffee Break Sponsor (x3) + 499€</li>*/}
              {/*      <li>Lunch Sponsor (x2) + 699€</li>*/}
              {/*      <li>Dinner Sponsor (x1) + 699€</li>*/}
              {/*      <li>Chairman Slot +399€</li>*/}
              {/*    </ul>*/}
              {/*    <ul className="addition-service-first">*/}
              {/*      <li>Workshop (20 min) +599€</li>*/}
              {/*      <li>Speaking Slot +999€</li>*/}
              {/*      <li>Exhibition Table +599€</li>*/}
              {/*      <li>1:1 Partnering Meetings +599€</li>*/}
              {/*      <li>Exhibition Stand 3x2 +699€</li>*/}
              {/*    </ul>*/}
              {/*  </div>*/}
              {/*  <div className="addition-btn-container">*/}
              {/*    <a href="mailto:daria.novoseltseva@bcf-events.com">*/}
              {/*      <button className="addition-btn">Learn more</button>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="addition-contact">
                <h3 className="addition-contact-title">CONTACT US</h3>
                <div className="line-container">
                  <span className="line-about"></span>
                </div>
                <p className="addition-contact-description">
                  For any further details or questions regarding sponsorship
                  opportunities, please contact
                </p>
                <h2 className="become-sponsor-title">BECOME A SPONSOR</h2>
                <img
                  className="contact-photo"
                  src={require("../../assets/images/daria.png")}
                  alt="Partner Image"
                />
                <h3 className="addition-name">Artyom Davydov</h3>
                <h4 className="addition-position">
                  Business Development Manager
                </h4>
                {/*<p className="phone-sponsorship">+420 228 227 163</p>*/}
                <p className="email-sponsorship">
                  artyom.davydov@bcfgroup.eu
                </p>
              </div>
            </div>
          </div>
          <Modal
              types="agenda"
              currentType={currentType}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
          >
            <div className="white-square">
              <div className="scroll-container-wrapper">
                <div className="scroll-container">
                  <form
                      className="form-bank"
                      onSubmit={(e) => postMessageToSlack(e, "deals_bt")}
                  >
                    <div className="form-group">
                      <label
                          htmlFor="firstName"
                          className="first-name-class"
                      >
                        Full Name*
                      </label>
                      <input
                          type="text"
                          id="full-name-input"
                          name="fullName"
                          required
                          value={agenda.fullName}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email*</label>
                      <input
                          type="email"
                          id="email-input"
                          name="email"
                          required
                          value={agenda.email}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="last-name">Phone*</label>
                      <input
                          type="phone"
                          id="phone-input"
                          name="phone"
                          required
                          value={agenda.phone}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="job-title">Job Title*</label>
                      <input
                          type="text"
                          id="job-title-input"
                          name="job"
                          required
                          value={agenda.job}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company-name">Company Name*</label>
                      <input
                          type="text"
                          id="company-name-input"
                          name="company"
                          required
                          value={agenda.company}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company-address">Company Address*</label>
                      <input
                          type="text"
                          id="company-adress-input"
                          name="address"
                          required
                          value={agenda.address}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">City*</label>
                      <input
                          type="text"
                          id="city-input"
                          name="city"
                          required
                          value={agenda.city}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="vat"> VAT of the company (only for EU company)</label>
                      <input
                          type="text"
                          id="vat-input"
                          name="vat"
                          value={agenda.vat}
                          onChange={(e) => handleAgenda(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="price">Package</label>
                      <input
                          type="text"
                          id="packages-input"
                          name="packages"
                          value={agenda.packages}
                          disabled
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="ticket-type">Event Name*</label>
                      <select
                          id="ticket-type"
                          name="eventName"
                          required
                          value={agenda.eventName}
                          onChange={(e) => handleAgenda(e)}
                          disabled={loading} // Disable the select while loading
                      >
                        <option value="">Select one</option>
                        {loading ? (
                            <option value="" disabled>Loading...</option>
                        ) : (
                            capitalizedEventNames.map((eventName, index) => (
                                <option key={`option-${index}`} value={eventName}>
                                  {eventName}
                                </option>
                            ))
                        )}
                      </select>
                    </div>
                    <button type="submit" className="canister-button">
                      { isLoading ? <ThreeDots  height="40" width="40" color="white" /> : "Send" }
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </section>
        <section>
          <section style={sectionStyle}>
            <div className="container container-special">
              <div style={containerStyle}>
                <span style={spanStyle}></span>
              </div>
              <Swiper
                  ref={swiperRef}
                  spaceBetween={10} // Расстояние между слайдами
                  slidesPerView={3} // Показ трех слайдов одновременно
                  centeredSlides={true} // Выделение среднего слайда
                  loop={true} // Зацикливание
                  pagination={{ clickable: true, dynamicBullets: true }} // Включаем пагинацию
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  style={{ padding: '20px 0' }}
              >
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_1.JPG")} alt="Photo 1" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_2.JPG")} alt="Photo 2" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_55.jpg")} alt="Photo 5" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_3.png")} alt="Photo 6" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_3.jpg")} alt="Photo 6" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_10.jpg")} alt="Photo 6" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_11.jpg")} alt="Photo 6" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_12.jpg")} alt="Photo 6" style={imgStyle} />
                </SwiperSlide>
                <SwiperSlide style={swiperSlideStyle}>
                  <img src={require("../../assets/images/sponsor_13.jpg")} alt="Photo 6" style={imgStyle} />
                </SwiperSlide>
              </Swiper>
              {/* Контейнер для точек пагинации */}
              <div className="swiper-pagination" style={paginationStyle}></div>
            </div>
          </section>
          <div className="container">
            <h2 className="title-about title-packages">
              Previous sponsoring companies
            </h2>
            <div className="line-container">
              <span className="line-about"></span>
            </div>
            <ul className="list-partners list-partners-padding">
              <li>
                <a href="https://www.crunchbase.com/organization/faromatics">
                <img
                  src={require("../../assets/images/parner1.png")}
                  data-hover-src={require("../../assets/images/parner-color1.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
              </a>
              </li>
              <li>
                <a href="#">
                <img
                  src={require("../../assets/images/parner2.png")}
                  data-hover-src={require("../../assets/images/parner-color2.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>

              <li>
                <a href="https://pharmaserve.com/out-of-coverage/">
                <img
                  src={require("../../assets/images/parner3.png")}
                  data-hover-src={require("../../assets/images/parner-color3.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>

              <li>
                <a href="https://acino.swiss/">
                <img
                  src={require("../../assets/images/parner4.png")}
                  data-hover-src={require("../../assets/images/parner-color4.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.aptar.com/">
                <img
                  src={require("../../assets/images/parner5.png")}
                  data-hover-src={require("../../assets/images/parner-color5.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.averydennison.com/en/home.html">
                <img
                  src={require("../../assets/images/parner6.png")}
                  data-hover-src={require("../../assets/images/parner-color6.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://www.tno.nl/en/">
                <img
                  src={require("../../assets/images/parner7.png")}
                  data-hover-src={require("../../assets/images/parner-color7.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://combilift.com/">
                <img
                  src={require("../../assets/images/parner8.png")}
                  data-hover-src={require("../../assets/images/parner-color8.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://coneva.com/en/">
                <img
                  src={require("../../assets/images/parner9.png")}
                  data-hover-src={require("../../assets/images/parner-color9.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.croda.com/en-gb">
                <img
                  src={require("../../assets/images/parner10.png")}
                  data-hover-src={require("../../assets/images/parner-color10.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.pragmaticsemi.com/">
                <img
                  src={require("../../assets/images/parner11.png")}
                  data-hover-src={require("../../assets/images/parner-color11.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.wuxiapptec.com/">
                <img
                  src={require("../../assets/images/parner12.png")}
                  data-hover-src={require("../../assets/images/parner-color12.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://www.inke.es/">
                <img
                  src={require("../../assets/images/parner13.png")}
                  data-hover-src={require("../../assets/images/parner-color13.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
              </a>
              </li>
              <li>
                <a href="https://www.ivirma.com/">
                <img
                  src={require("../../assets/images/parner14.png")}
                  data-hover-src={require("../../assets/images/parner-color14.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.leonhard-kurz.com/">
                <img
                  src={require("../../assets/images/parner15.png")}
                  data-hover-src={require("../../assets/images/parner-color15.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.nelsonlabs.com/">
                <img
                  src={require("../../assets/images/parner16.png")}
                  data-hover-src={require("../../assets/images/parner-color16.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://nanologica.com/">
                <img
                  src={require("../../assets/images/parner17.png")}
                  data-hover-src={require("../../assets/images/parner-color17.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://ru.linkedin.com/company/link3dinc">
                <img
                  src={require("../../assets/images/parner18.png")}
                  data-hover-src={require("../../assets/images/parner-color18.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://www.serket-tech.com/">
                <img
                  src={require("../../assets/images/parner19.png")}
                  data-hover-src={require("../../assets/images/parner-color19.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.cowhouse.nl/en">
                <img
                  src={require("../../assets/images/parner20.png")}
                  data-hover-src={require("../../assets/images/parner-color20.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.dke.de/en">
                <img
                  src={require("../../assets/images/parner21.png")}
                  data-hover-src={require("../../assets/images/parner-color21.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.perlenpackaging.com/en/">
                <img
                  src={require("../../assets/images/parner22.png")}
                  data-hover-src={require("../../assets/images/parner-color22.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.precihealth.com/">
                <img
                  src={require("../../assets/images/parner23.png")}
                  data-hover-src={require("../../assets/images/parner-color23.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.septodontcorp.com/">
                <img
                  src={require("../../assets/images/parner24.png")}
                  data-hover-src={require("../../assets/images/parner-color24.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://www.materialise.com/en">
                <img
                  src={require("../../assets/images/parner25.png")}
                  data-hover-src={require("../../assets/images/parner-color25.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.intertek.com/">
                <img
                  src={require("../../assets/images/26.png")}
                  data-hover-src={require("../../assets/images/parner-color26.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.ypsomed.com/">
                <img
                  src={require("../../assets/images/27.png")}
                  data-hover-src={require("../../assets/images/parner-color27.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.catalent.com/">
                <img
                  src={require("../../assets/images/28.png")}
                  data-hover-src={require("../../assets/images/parner-color28.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.grifols.com/">
                <img
                  src={require("../../assets/images/29.png")}
                  data-hover-src={require("../../assets/images/parner-color29.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://livestock.datamars.com/">
                  <img
                      src={require("../../assets/images/parner30.png")}
                      data-hover-src={require("../../assets/images/parner-color30.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://tessy.com/">
                <img
                  src={require("../../assets/images/parner31.png")}
                  data-hover-src={require("../../assets/images/parner-color31.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://agrirouter.com/">
                <img
                  src={require("../../assets/images/parner32.png")}
                  data-hover-src={require("../../assets/images/parner-color32.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.radiantvisionsystems.com">
                <img
                  src={require("../../assets/images/parner33.png")}
                  data-hover-src={require("../../assets/images/parner-color33.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.eye-square.com/">
                <img
                  src={require("../../assets/images/parner34.png")}
                  data-hover-src={require("../../assets/images/parner-color34.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://nanotron.com/">
                <img
                  src={require("../../assets/images/parner35.png")}
                  data-hover-src={require("../../assets/images/parner-color35.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.soundtalks.com/">
                <img
                  src={require("../../assets/images/parner36.png")}
                  data-hover-src={require("../../assets/images/parner-color36.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://pfanstiehl.com/">
                <img
                  src={require("../../assets/images/parner37.png")}
                  data-hover-src={require("../../assets/images/parner-color37.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.twikit.com/">
                <img
                  src={require("../../assets/images/parner38.png")}
                  data-hover-src={require("../../assets/images/parner-color38.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.broughton-group.com/">
                <img
                  src={require("../../assets/images/parner39.png")}
                  data-hover-src={require("../../assets/images/parner-color39.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://presspart.com/">
                <img
                  src={require("../../assets/images/parner40.png")}
                  data-hover-src={require("../../assets/images/parner-color40.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.knoell.com/en">
                <img
                  src={require("../../assets/images/parner41.png")}
                  data-hover-src={require("../../assets/images/parner-color41.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.austriajuice.com/en/home">
                <img
                  src={require("../../assets/images/parner42.png")}
                  data-hover-src={require("../../assets/images/parner-color42.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://corporate.evonik.com/en">
                <img
                  src={require("../../assets/images/parner44.png")}
                  data-hover-src={require("../../assets/images/parner-color44.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.hydro.com">
                <img
                  src={require("../../assets/images/parner53.png")}
                  data-hover-src={require("../../assets/images/parner-color53.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.bossard.com/">
                <img
                  src={require("../../assets/images/parner46.png")}
                  data-hover-src={require("../../assets/images/parner-color46.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.munit.com/">
                <img
                  src={require("../../assets/images/parner47.png")}
                  data-hover-src={require("../../assets/images/parner-color47.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.meggle-pharma.com/">
                <img
                  src={require("../../assets/images/parner48.png")}
                  data-hover-src={require("../../assets/images/parner-color48.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="">
                  <img
                      src={require("../../assets/images/parner43.png")}
                      data-hover-src={require("../../assets/images/parner-color43.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://arrival.com/">
                <img
                  src={require("../../assets/images/parner49.png")}
                  data-hover-src={require("../../assets/images/parner-color49.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://globalpetindustry.com/company/tekstilna-tovarna-okroglica-dd">
                <img
                  src={require("../../assets/images/parner50.png")}
                  data-hover-src={require("../../assets/images/parner-color50.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://www.pcqtech.com/">
                <img
                  src={require("../../assets/images/parner51.png")}
                  data-hover-src={require("../../assets/images/parner-color51.png")}
                  onMouseEnter={changeImage}
                  onMouseLeave={restoreImage}
                  alt="Partner Image"
                />
                </a>
              </li>
              <li>
                <a href="https://max-ai.com/">
                  <img
                      src={require("../../assets/images/parner52.png")}
                      data-hover-src={require("../../assets/images/parner-color52.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://containment-technology.co.uk/">
                  <img
                      src={require("../../assets/images/parner55-transformed.png")}
                      data-hover-src={require("../../assets/images/parner-color55-transformed.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.basf.com/">
                  <img
                      src={require("../../assets/images/parner54-transformed.png")}
                      data-hover-src={require("../../assets/images/parner-color54-transformed.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://ebi.bio/">
                  <img
                      src={require("../../assets/images/parner56.png")}
                      data-hover-src={require("../../assets/images/parner-color56.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.cormica.com/">
                  <img
                      src={require("../../assets/images/black57.png")}
                      data-hover-src={require("../../assets/images/parner57.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.groupeicare.com/en/home/">
                  <img
                      src={require("../../assets/images/black58.png")}
                      data-hover-src={require("../../assets/images/parner58.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://sgs-institut-fresenius.de/">
                  <img
                      src={require("../../assets/images/black59.png")}
                      data-hover-src={require("../../assets/images/parner59.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.merieuxnutrisciences.com/">
                  <img
                      src={require("../../assets/images/black60.png")}
                      data-hover-src={require("../../assets/images/parner60.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
              <li>
                <a href="https://namsa.com/">
                  <img
                      src={require("../../assets/images/black61.png")}
                      data-hover-src={require("../../assets/images/parner61.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
            </ul>
            <ul className="list-partners">
              <li>
                <a href="https://www.eurofins.com/biopharma-services/">
                  <img
                      src={require("../../assets/images/black62.png")}
                      data-hover-src={require("../../assets/images/parner62.png")}
                      onMouseEnter={changeImage}
                      onMouseLeave={restoreImage}
                      alt="Partner Image"
                  />
                </a>
              </li>
            <li className="empty-sponsor"></li>
            <li className="empty-sponsor"></li>
            <li className="empty-sponsor"></li>
              <li className="empty-sponsor"></li>
              <li className="empty-sponsor"></li>
            </ul>
          </div>
        </section>
        <section>
          <div className="container">
            <h2 className="title-about diagram-title">Our attendees</h2>
            <div className="line-container">
              <span className="line-about"></span>
            </div>
            <div className="diagram-container">
              <img
                className="diagram-desktop"
                src={require("../../assets/images/diagrams.png")}
                alt="Partner Image"
              />
              <img
                className="diagram-mobile"
                src={require("../../assets/images/diagrams-mobile.png")}
                alt="Partner Image"
              />
              <img
                className="diagram-tablet"
                src={require("../../assets/images/diagrams-tablet.png")}
                alt="Partner Image"
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Sponsorship;
