import React, { useEffect, useState, useMemo } from 'react';
import api from "../../api/endpoints/events";
import { useParams } from "react-router-dom";
import logo from '../../assets/images/logo.png';

function Header() {
    const { id } = useParams();
    const [conference, setConference] = useState({});
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Отслеживаем изменение ширины окна
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Определяем тип устройства по ширине окна
    const isMobile = windowWidth < 768;
    const isTablet = windowWidth >= 768 && windowWidth < 1024;
    const isDesktop = windowWidth >= 1024;

    // Загружаем данные конференции (если нужно)
    useEffect(() => {
        const fetchConferenceData = async () => {
            try {
                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                setConference(data);
            } catch (error) {
                console.error('Error fetching conference data:', error);
            }
        };

        if (id) {
            fetchConferenceData();
        }
    }, [id]);

    // Отслеживаем скролл для смены цвета фона шапки
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Переключаем мобильное меню
    const toggleMenu = () => {
        setIsOpened(prev => !prev);
    };

    // Мемоизированные значения и стили
    const logoSrc = useMemo(() => logo, []);
    const liStyle = useMemo(() => ({ color: '#fff', marginRight: '10px' }), []);
    const logoStyle = useMemo(() => ({
        // Для планшета немного уменьшаем логотип
        height: isTablet ? '40px' : '50px',
        width: 'auto',
        marginRight: '10px',
    }), [isTablet]);
    const headerStyle = useMemo(() => ({
        paddingTop: isTablet ? '15px' : '25px',
        backgroundColor: isScrolled ? '#313F50' : 'initial',
        paddingBottom: '10px',
    }), [isScrolled, isTablet]);

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`} style={headerStyle}>
            {/* Десктоп версия */}
            {isDesktop && (
                <div className="desktop-header" style={{ paddingTop: '10px', marginTop: '10px', height: '40px' }}>
                    <a href='/'>
                        <img src={logoSrc} alt="Logo" style={logoStyle} />
                    </a>
                    <nav>
                        <ul className="nav-list">
                            <li style={liStyle}><a href="/membershipform" style={liStyle}>MEMBERSHIP</a></li>
                            <li style={liStyle}><a href="/about" style={liStyle}>ABOUT US</a></li>
                            <li style={liStyle}><a href='/sponsorship' style={liStyle}>SPONSORSHIP</a></li>
                            <li style={liStyle}><a href='/conferences' style={liStyle}>B2B CONFERENCES</a></li>
                            <li style={liStyle}><a href="/faq" style={liStyle}>FAQs</a></li>
                            <li style={liStyle}><a href="/gallery" style={liStyle}>GALLERY</a></li>
                            <li style={liStyle}><a href='/events' style={liStyle}>PAST EVENTS</a></li>
                            <li style={liStyle}><a href='/contacts' style={liStyle}>CONTACT US</a></li>
                        </ul>
                    </nav>
                </div>
            )}

            {/* Мобильная и планшетная версия */}
            {(isMobile || isTablet) && (
                <div className="mobile-menu">
                    <div className="mobile-header" style={{ backgroundColor: isScrolled ? '#313F50' : 'initial' }}>
                        <div className="flex justify-between items-center mobile-class">
                            <div className="block-output">
                                <a href='/'>
                                    <img
                                        src={logoSrc}
                                        style={{
                                            // Для планшета немного увеличиваем размеры по сравнению с мобильной версией
                                            width: isTablet ? '85px' : '71px',
                                            height: isTablet ? '30px' : '26px'
                                        }}
                                        className="logo-laptop"
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                            <div className="hamburger-container">
                                <button
                                    className={`hamburger-button ${isOpened ? 'active' : ''}`}
                                    onClick={toggleMenu}
                                    aria-label="Toggle menu"
                                >
                                    <span className="bar bar1"></span>
                                    <span className="bar bar2"></span>
                                    <span className="bar bar3"></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {isOpened && (
                        <div className="mobile-nav mobile-menu open">
                            <ul className="list-navigation">
                                <a href="/"><li className="mobile-list">HOME</li></a>
                                <a href="/membershipform"><li className="mobile-list">MEMBERSHIP</li></a>
                                <a href="/about"><li className="mobile-list">ABOUT US</li></a>
                                <a href="/sponsorship"><li className="mobile-list">SPONSORSHIP</li></a>
                                <a href='/conferences'><li className="mobile-list">B2B CONFERENCES</li></a>
                                <a href="/faq"><li className="mobile-list">FAQs</li></a>
                                <a href="/gallery"><li className="mobile-list">GALLERY</li></a>
                                <a href="/events"><li className="mobile-list">PAST EVENTS</li></a>
                                <a href="/contacts"><li className="mobile-list">CONTACT US</li></a>
                                <span className="line-burger"></span>
                                <div className="social-mobile">
                                    <a href="#">
                                        <img
                                            src="https://i.ibb.co/2s3CrMg/telegram-mobile.png"
                                            alt="telegram"
                                        />
                                    </a>
                                    <a href="https://www.instagram.com/bcfgroupeu/">
                                        <img
                                            src="https://i.ibb.co/d2RWsz8/instagram-mobile.png"
                                            alt="instagram"
                                        />
                                    </a>
                                    <a href="https://twitter.com/BCFgroupeu">
                                        <img
                                            src="https://i.ibb.co/9Wcv40d/twitter-mobile.png"
                                            alt="twitter"
                                        />
                                    </a>
                                    <a href="#">
                                        <img
                                            src="https://i.ibb.co/DtCYbB8/google-mobile.png"
                                            alt="google"
                                        />
                                    </a>
                                    <a href="https://www.linkedin.com/company/business-conference-facilitation/">
                                        <img
                                            src="https://i.ibb.co/By4k6qH/linkedin-mobile.png"
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </header>
    );
}

export default Header;
